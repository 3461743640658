import React from "react";
import { useIntl } from "react-intl";
import { Dropdown } from "./Dropdown";
import {
  VotableOrder,
  formatVotableOrder,
  VOTABLE_ORDERS,
} from "../utils/votableOrder";
import { MdArrowDropDown } from "react-icons/md";

interface VotableOrderSelectTriggerProps {
  value: VotableOrder;
}

const VotableOrderSelectTrigger = React.forwardRef<
  HTMLButtonElement,
  VotableOrderSelectTriggerProps
>(({ value, ...props }, ref) => {
  const intl = useIntl();
  return (
    <button className="hover:cursor-pointer" ref={ref} {...props}>
      {formatVotableOrder(intl, value)}{" "}
      <MdArrowDropDown className="inline-block" />
    </button>
  );
});

export interface VotableOrderSelectProps {
  value: VotableOrder;
  onChange: (value: VotableOrder) => void;
}

export default function VotableOrderSelect({
  value,
  onChange,
}: VotableOrderSelectProps) {
  const intl = useIntl();
  return (
    <Dropdown autoCloses trigger={<VotableOrderSelectTrigger value={value} />}>
      <Dropdown.List>
        {VOTABLE_ORDERS.map((order) => (
          <Dropdown.Item
            key={order}
            onClick={() => onChange(order)}
            selected={order === value}
          >
            {formatVotableOrder(intl, order)}
          </Dropdown.Item>
        ))}
      </Dropdown.List>
    </Dropdown>
  );
}
