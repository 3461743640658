import {
  UseMutationConfig,
  PreloadedQuery,
  loadQuery,
  usePreloadedQuery,
  fetchQuery,
  Environment,
} from "react-relay";
import {
  MutationParameters,
  Disposable,
  OperationType,
  GraphQLTaggedNode,
} from "relay-runtime";
import { useLoaderData } from "react-router-dom";

export function commitMutationPromise<TMutation extends MutationParameters>(
  func: (config: UseMutationConfig<TMutation>) => Disposable,
): (config: UseMutationConfig<TMutation>) => Promise<TMutation["response"]> {
  return (config) => {
    return new Promise((resolve, reject) => {
      const { onCompleted, onError, ...rest } = config;
      func({
        ...rest,
        onCompleted: (response, errors) => {
          if (onCompleted) {
            onCompleted(response, errors);
          }
          resolve(response);
        },
        onError: (error) => {
          if (onError) {
            onError(error);
          }
          reject(error);
        },
      });
    });
  };
}

export interface PreloadedData<TQuery extends OperationType> {
  graphql: GraphQLTaggedNode;
  variables: TQuery["variables"];
  query: PreloadedQuery<TQuery>;
}

export const preload = <TQuery extends OperationType>(
  environment: Environment,
  graphql: GraphQLTaggedNode,
  variables: TQuery["variables"] = {},
): PreloadedData<TQuery> => {
  return {
    graphql,
    variables,
    query: loadQuery<TQuery>(environment, graphql, variables),
  };
};

export const reload = <TQuery extends OperationType>(
  environment: Environment,
  preloaded: PreloadedData<TQuery>,
): PreloadedData<TQuery> => {
  const { graphql, variables, query: _query, ...rest } = preloaded;
  return {
    graphql,
    variables,
    query: loadQuery<TQuery>(environment, graphql, variables),
    ...rest,
  };
};

export const usePreloaded = <TQuery extends OperationType>() => {
  const { variables, query, graphql, ...rest } =
    useLoaderData() as PreloadedData<TQuery>;
  return {
    variables,
    query: usePreloadedQuery<TQuery>(graphql, query),
    ...rest,
  };
};

export interface LoadedData<TQuery extends OperationType> {
  graphql: GraphQLTaggedNode;
  variables: TQuery["variables"];
  response: TQuery["response"];
}

export const load = async <TQuery extends OperationType>(
  environment: Environment,
  graphql: GraphQLTaggedNode,
  variables: TQuery["variables"] = {},
): Promise<LoadedData<TQuery>> => {
  const response = await fetchQuery<TQuery>(
    environment,
    graphql,
    variables,
  ).toPromise();
  return {
    graphql,
    variables,
    response,
  };
};

export const useLoaded = <TQuery extends OperationType>() => {
  const data = useLoaderData() as LoadedData<TQuery>;
  return data;
};

export interface RelayPayloadError {
  message: string;
  path: string[];
  extensions?: Record<string, unknown>;
}

export interface RelayNetworkErrorSource {
  errors: RelayPayloadError[];
}

export interface RelayNetworkError extends Error {
  source: RelayNetworkErrorSource;
  operation: OperationType;
  variables: Record<string, unknown>;
}

export function isRelayNetworkError(
  error: unknown,
): error is RelayNetworkError {
  return (
    typeof error === "object" &&
    error !== null &&
    "name" in error &&
    error.name === "RelayNetwork"
  );
}

export function collectRelayPayloadErrorCodes<K extends string = string>(
  error: RelayNetworkError,
): { [K0 in K]?: RelayPayloadError } {
  return Object.fromEntries(
    error.source.errors.map((error) => [error.extensions?.["code"], error]),
  );
}

export function relayErrorMessage(error: Error, combine = true) {
  if (isRelayNetworkError(error)) {
    if (error.source.errors.length > 0) {
      if (combine) {
        return error.source.errors.map((error) => error.message).join(". ");
      } else {
        return error.source.errors[0].message;
      }
    }
  }
  return error.message;
}
