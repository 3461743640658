import { useState, useEffect, useCallback } from "react";
import { graphql, useMutation } from "react-relay";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "sonner";

import { useVerifyEmailSignupPageQuery } from "./loaders/VerifyEmailSignupPage";

import ErrorPage from "./ErrorPage";
import FormGroup from "../components/FormGroup";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import { TimeAgo } from "../components/TimeAgo";
import { MetaLayout } from "../common/MetaLayout";

import { relayErrorMessage } from "../utils/relay";

import { VerifyEmailSignupPageCheckMutation as VerifyEmailSignupPageCheckMutationType } from "./__generated__/VerifyEmailSignupPageCheckMutation.graphql";
import { VerifyEmailSignupPageResendMutation as VerifyEmailSignupPageResendMutationType } from "./__generated__/VerifyEmailSignupPageResendMutation.graphql";

const VerifyEmailSignupPageCheckMutation = graphql`
  mutation VerifyEmailSignupPageCheckMutation(
    $input: EmailSignupVerficationInput!
  ) {
    checkEmailSignupVerification(input: $input) {
      id
      emailCanResendAt
    }
  }
`;

const VerifyEmailSignupPageResendMutation = graphql`
  mutation VerifyEmailSignupPageResendMutation($id: ID!) {
    resendEmailSignupVerification(id: $id) {
      id
      emailCanResendAt
    }
  }
`;

interface ResendButtonProps {
  id: string;
  emailCanResendAt: string;
}

const ResendButton = ({ id, emailCanResendAt }: ResendButtonProps) => {
  const intl = useIntl();
  const [commitResendMutation, isResendMutationInFlight] =
    useMutation<VerifyEmailSignupPageResendMutationType>(
      VerifyEmailSignupPageResendMutation,
    );

  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 500);
    return () => clearInterval(interval);
  });

  const onResend = () => {
    if (!canResend) {
      return;
    }
    commitResendMutation({
      variables: { id },
      onCompleted() {
        toast.success(intl.formatMessage({ defaultMessage: "Email resent" }));
      },
      onError(error) {
        toast.error(
          intl.formatMessage(
            { defaultMessage: "Failed to resend email: {message}" },
            { message: relayErrorMessage(error) },
          ),
        );
      },
    });
  };

  const resendTime = new Date(emailCanResendAt);
  const canResend = currentTime >= resendTime;

  return (
    <Button
      suppressHydrationWarning
      className="w-full"
      kind="text"
      disabled={!canResend || isResendMutationInFlight}
      onClick={onResend}
    >
      {canResend ? (
        <FormattedMessage defaultMessage="Resend Email" />
      ) : (
        <FormattedMessage
          defaultMessage="Can resend email {time}"
          values={{
            time: <TimeAgo createdAt={resendTime} />,
          }}
        />
      )}
    </Button>
  );
};

interface FormData {
  token: string;
}

export default function VerifyEmailSignupPage() {
  const intl = useIntl();
  const navigate = useNavigate();

  const { state } = useLocation();
  const email = state?.email;

  const {
    query: {
      node: { id, emailCanResendAt },
    },
  } = useVerifyEmailSignupPageQuery();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [commitCheckMutation, isCheckMutationInFlight] =
    useMutation<VerifyEmailSignupPageCheckMutationType>(
      VerifyEmailSignupPageCheckMutation,
    );

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormData>();

  const doTokenSubmit = useCallback(
    (data: FormData) => {
      if (!isValid) {
        return;
      }
      const input = { id, token: data.token };
      commitCheckMutation({
        variables: { input },
        onCompleted() {
          navigate("/setup-user", {
            state: { emailSignup: input },
          });
        },
        onError(error) {
          toast.error(
            intl.formatMessage(
              { defaultMessage: "Could not validate: {message}" },
              { message: relayErrorMessage(error) },
            ),
          );
        },
      });
    },
    [isValid, commitCheckMutation, id, intl, navigate],
  );

  useEffect(() => {
    if (token) {
      doTokenSubmit({ token });
    }
  }, [token, doTokenSubmit]);

  if (!id || !emailCanResendAt) {
    return (
      <ErrorPage status={404}>
        <FormattedMessage defaultMessage="Email verification not found or expired." />
      </ErrorPage>
    );
  }

  return (
    <MetaLayout
      metaTitle={intl.formatMessage({
        defaultMessage: "Verify your email - Join the Global Quantum Community",
      })}
    >
      <h1 className="text-xl font-bold mb-2">
        <FormattedMessage defaultMessage="Verify your email" />
      </h1>
      <p className="text-gray-500 mb-4">
        <FormattedMessage
          defaultMessage={`We've sent an email with a 6 digit code{email, select,
            undefined {}
            null {}
            other { to {email}}
          }. Please enter it here.`}
          values={{ email: email ? <b>{email}</b> : null }}
        />
      </p>
      <form onSubmit={handleSubmit(doTokenSubmit)}>
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "Verification Code" })}
        >
          <TextInput
            placeholder={intl.formatMessage({ defaultMessage: "6 digit code" })}
            defaultValue={token || ""}
            {...register("token", {
              required: true,
              pattern: /^[0-9]{6}$/,
            })}
          />
        </FormGroup>
        <div className="flex flex-col gap-2 mt-6">
          <Button
            className="w-full"
            type="submit"
            disabled={!isValid || isCheckMutationInFlight}
          >
            <FormattedMessage defaultMessage="Verify" />
          </Button>
          <ResendButton id={id} emailCanResendAt={emailCanResendAt} />
        </div>
      </form>
    </MetaLayout>
  );
}
