import { FieldValues, UseControllerProps } from "react-hook-form";
import React, { createContext, useContext } from "react";
import { MemberSelectCompetitionFragment$key } from "./__generated__/MemberSelectCompetitionFragment.graphql";
import { MemberSelectEventFragment$key } from "./__generated__/MemberSelectEventFragment.graphql";
import { MemberSelectAllEntitiesAutoCompleteFragment$key } from "./__generated__/MemberSelectAllEntitiesAutoCompleteFragment.graphql";

type ContextType = "event" | "competition" | "all" | "none";

export type MarkdownValue = string | undefined;

export type ContextTypeMembers =
  | {
      kind: Extract<ContextType, "competition">;
      members: MemberSelectCompetitionFragment$key;
    }
  | {
      kind: Extract<ContextType, "event">;
      members: MemberSelectEventFragment$key;
    }
  | {
      kind: Extract<ContextType, "all">;
      members: MemberSelectAllEntitiesAutoCompleteFragment$key;
    }
  | { kind: Extract<ContextType, "none"> };

export interface MarkdownEditorContextProps<TFieldValues extends FieldValues>
  extends UseControllerProps<TFieldValues> {
  canUploadFiles?: boolean;
  setMarkdownValue: React.Dispatch<React.SetStateAction<MarkdownValue>>;
  markdownValue: MarkdownValue;
  members?: ContextTypeMembers;
  onResetForm?: () => void;
}

export const MarkdownEditorContext = createContext<
  // eslint-disable-next-line
  MarkdownEditorContextProps<any> | undefined
>(undefined);

export const useMarkdownEditor = <TFieldValues extends FieldValues>() => {
  const context = useContext(
    MarkdownEditorContext as React.Context<
      MarkdownEditorContextProps<TFieldValues> | undefined
    >,
  );
  if (!context) {
    throw new Error(
      "useMarkdownEditor must be used within a MarkdownEditorProvider",
    );
  }
  return context;
};
