/**
 * @generated SignedSource<<2a68746f03f1d0b0c126518a9ebd5701>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProjectVersionStatus = "AWAITING_APPROVAL" | "AWAITING_EVALUATION" | "AWAITING_VALIDATION" | "ERROR" | "OK";
import { FragmentRefs } from "relay-runtime";
export type SubmissionStatusBadgeFragment$data = {
  readonly evaluation: {
    readonly error: string | null | undefined;
    readonly finalizedAt: string | null | undefined;
    readonly max: boolean;
    readonly score: number | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly project: {
    readonly competition: {
      readonly hasLeaderboard: boolean;
      readonly noCode: boolean;
    };
  };
  readonly status: ProjectVersionStatus;
  readonly version: string;
  readonly " $fragmentType": "SubmissionStatusBadgeFragment";
};
export type SubmissionStatusBadgeFragment$key = {
  readonly " $data"?: SubmissionStatusBadgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubmissionStatusBadgeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubmissionStatusBadgeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectVersionEvaluation",
      "kind": "LinkedField",
      "name": "evaluation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "score",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "error",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finalizedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "max",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Competition",
          "kind": "LinkedField",
          "name": "competition",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasLeaderboard",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "noCode",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectVersion",
  "abstractKey": null
};

(node as any).hash = "980c45e7e01ad453ac45e644f4e7850c";

export default node;
