import { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { graphql, useMutation } from "react-relay";
import { UploadableMap } from "relay-runtime";
import { Link, useLocation as useRouterLocation } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import { logger } from "../common/logger";
import { useOnAuthCallback } from "../utils/auth";
import ProfilePic from "../components/ProfilePic";
import Button from "../components/Button";
import FormGroup from "../components/FormGroup";
import MarkdownEditor from "../components/MarkdownEditor";
import TextInput from "../components/TextInput";
import CheckUsernameInput from "../components/CheckUsernameInput";
import { maxFileSize } from "../utils/validation";
import { useImagePreview, useUpload } from "../utils/hooks";
import AspectDiv from "../components/AspectDiv";
import { MetaLayout } from "../common/MetaLayout";
import {
  type MarkdownEditorContextProps,
  type MarkdownValue,
} from "../components/MarkdownEditor/context";
import { MarkdownEditorProvider } from "../components/MarkdownEditor/Provider";
import { SetupUserPageMutation as SetupUserPageMutationType } from "./__generated__/SetupUserPageMutation.graphql";

const SetupUserPageMutation = graphql`
  mutation SetupUserPageMutation($input: SignupUserInput!) {
    signupUser(input: $input) {
      node {
        id
      }
    }
  }
`;

export interface FormData {
  username: string;
  displayName: string;
  bio: string;
  image: FileList;
  subscribePromotionalNewsletter: boolean;
}

export type SubmitData<T> = {
  data: T;
  image: { variable: null | undefined; uploadable: File | undefined };
  setFormError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export default function EntityEditForm() {
  const intl = useIntl();
  const onAuthenticated = useOnAuthCallback();

  const form = useForm<FormData>({ mode: "onBlur" });
  const {
    register,
    resetField,
    watch,
    control,
    formState: { errors, isValid },
  } = form;
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [markdownValue, setMarkdownValue] = useState<MarkdownValue>("");
  const image = useUpload(undefined, watch("image"), () => resetField("image"));
  const imagePreview = useImagePreview(image.value);

  const { state } = useRouterLocation();
  const emailSignup = state?.emailSignup;

  const [commitMutation, isMutationInFlight] =
    useMutation<SetupUserPageMutationType>(SetupUserPageMutation);
  const isDisabled = isMutationInFlight || !isValid;

  const onSubmit = form.handleSubmit(
    ({ username, displayName, bio, subscribePromotionalNewsletter }) => {
      setFormError(undefined);
      const variables = {
        input: {
          username,
          displayName,
          bio: bio.trim() ? bio : undefined,
          subscribePromotionalNewsletter,
          emailSignup: emailSignup,
          image: image.variable,
        },
      };
      const uploadables: UploadableMap = {};
      if (image.value) {
        uploadables["variables.input.image"] = image.value;
      }
      commitMutation({
        variables,
        uploadables,
        onError(error) {
          logger.error(error);
          setFormError(
            intl.formatMessage({
              defaultMessage:
                "Could not create account. Username or email may already exist. Check your credentials and try again.",
            }),
          );
        },
        onCompleted() {
          onAuthenticated();
        },
      });
    },
  );

  const errorMessages = {
    displayName: {
      required: intl.formatMessage({ defaultMessage: "Name is required" }),
      maxLength: intl.formatMessage({
        defaultMessage: "Name must be at most 50 characters",
      }),
    },
    username: {
      required: intl.formatMessage({ defaultMessage: "Username is required" }),
      minLength: intl.formatMessage({
        defaultMessage: "Username must be at least 3 characters",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "Username must be at most 20 characters",
      }),
      pattern: intl.formatMessage({
        defaultMessage:
          "Username can only contain letters, numbers, and underscores",
      }),
      isNotBannedUsername: intl.formatMessage({
        defaultMessage: "Username not allowed",
      }),
      isNotTaken: intl.formatMessage({
        defaultMessage: "Username already taken",
      }),
    },
    image: {
      size: intl.formatMessage({
        defaultMessage: "Image must be at most 2 MB",
      }),
    },
    password: {
      minLength: intl.formatMessage({
        defaultMessage: "Password must be at least 6 characters",
      }),
    },
    oldPassword: {
      required: intl.formatMessage({ defaultMessage: "Password is required" }),
    },
    bio: {
      maxLength: intl.formatMessage({
        defaultMessage: "Bio must be at most 2000 characters",
      }),
    },
  };

  const contextValue: MarkdownEditorContextProps<FormData> = {
    name: "bio",
    control,
    defaultValue: "",
    setMarkdownValue,
    markdownValue,
    canUploadFiles: false,
    rules: { maxLength: 2000 },
  };

  if (!emailSignup) {
    return (
      <ErrorPage status={404}>
        <FormattedMessage defaultMessage="Email verification not found or expired." />
      </ErrorPage>
    );
  }

  return (
    <MetaLayout
      metaTitle={intl.formatMessage({
        defaultMessage:
          "Let's get to know you - Join the Global Quantum Community",
      })}
    >
      <h1 className="text-xl font-bold mb-2">
        <FormattedMessage defaultMessage="Let's get to know you" />
      </h1>
      <p className="text-gray-500 mb-4">
        <FormattedMessage defaultMessage="One last thing before you join the community" />
      </p>
      <form onSubmit={onSubmit}>
        <div className="space-y-6">
          <div className="flex flex-col space-y-3">
            <FormGroup
              label={intl.formatMessage({ defaultMessage: "Username" })}
              error={
                typeof errors.username?.type === "string" &&
                errorMessages.username[
                  errors.username.type as keyof typeof errorMessages.username
                ]
              }
            >
              <CheckUsernameInput
                aria-invalid={errors.username ? "true" : "false"}
                control={control}
                {...register("username", {
                  required: true,
                })}
              />
            </FormGroup>
            <FormGroup
              label={intl.formatMessage({ defaultMessage: "Name" })}
              error={
                typeof errors.displayName?.type === "string" &&
                errorMessages.displayName[
                  errors.displayName
                    .type as keyof typeof errorMessages.displayName
                ]
              }
            >
              <TextInput
                aria-invalid={errors.displayName ? "true" : "false"}
                {...register("displayName", {
                  setValueAs: (value) => value.trim(),
                  required: true,
                  maxLength: 50,
                })}
              />
            </FormGroup>
            <FormGroup
              label={intl.formatMessage({ defaultMessage: "Picture" })}
              error={
                typeof errors.image?.type === "string" &&
                errorMessages.image[
                  errors.image.type as keyof typeof errorMessages.image
                ]
              }
            >
              <div className="flex flex-row items-center space-x-3">
                <div className="w-28">
                  {image.isDirty ? (
                    <AspectDiv ratio={1} className="rounded-full">
                      <ProfilePic
                        src={imagePreview}
                        className="h-full"
                        alt={intl.formatMessage({
                          defaultMessage: "Thumbnail preview",
                        })}
                      />
                    </AspectDiv>
                  ) : (
                    <ProfilePic
                      alt={intl.formatMessage({
                        defaultMessage: "Default image preview",
                      })}
                    />
                  )}
                </div>
                <div>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    aria-invalid={errors.image ? "true" : "false"}
                    {...register("image", {
                      validate: {
                        size: maxFileSize(1024 * 1024 * 2), // 2 MB
                      },
                    })}
                  />
                  <div className="flex flex-row">
                    {image.isDirty && (
                      <Button kind="text" onClick={image.reset}>
                        <FormattedMessage defaultMessage="Reset" />
                      </Button>
                    )}
                    {image.canDelete && (
                      <Button kind="text" onClick={image.deleteImage}>
                        <FormattedMessage defaultMessage="Delete" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </FormGroup>
            <MarkdownEditorProvider value={contextValue}>
              <FormGroup
                label={intl.formatMessage({ defaultMessage: "Bio" })}
                error={
                  typeof errors.bio?.type === "string" &&
                  errorMessages.bio[
                    errors.bio.type as keyof typeof errorMessages.bio
                  ]
                }
              >
                <MarkdownEditor
                  rows={15}
                  aria-invalid={errors.bio ? "true" : "false"}
                />
              </FormGroup>
            </MarkdownEditorProvider>
          </div>
          <FormGroup
            label={intl.formatMessage({
              defaultMessage: "Weekly newsletter",
            })}
          >
            <div className="flex flex-row gap-2 justify-start">
              <div>
                <input
                  {...control.register("subscribePromotionalNewsletter")}
                  type="checkbox"
                />
              </div>
              <p className="text-sm text-gray-600">
                <FormattedMessage defaultMessage="I want to stay updated with weekly highlights of Aqora competitions, quantum news, and community insights from your platform." />
              </p>
            </div>
          </FormGroup>
          <p className="pt-3 text-sm text-gray-600">
            <FormattedMessage
              defaultMessage="By clicking 'Create Account' below you agree to the <terms>Terms & Conditions</terms> and the <privacy>Privacy Policy</privacy>."
              values={{
                terms: (chunks) => (
                  <Link
                    to={"https://aqora.io/terms"}
                    target="_blank"
                    className="text-indigo"
                  >
                    {chunks}
                  </Link>
                ),
                privacy: (chunks) => (
                  <Link
                    to={"https://aqora.io/privacy-policy"}
                    target="_blank"
                    className="text-indigo"
                  >
                    {chunks}
                  </Link>
                ),
              }}
            />
          </p>
          <div>
            {formError && (
              <p className="pb-4 text-sm text-red-500">{formError}</p>
            )}
            <Button
              className="w-full"
              kind="primary"
              onClick={onSubmit}
              disabled={isDisabled}
            >
              <FormattedMessage defaultMessage="Create Account" />
            </Button>
          </div>
        </div>
      </form>
    </MetaLayout>
  );
}
