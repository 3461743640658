import { FormattedMessage, useIntl } from "react-intl";
import FormGroup from "./FormGroup";
import { graphql, useLazyLoadQuery } from "react-relay";
import AspectDiv from "./AspectDiv";
import { Control, useForm, useWatch } from "react-hook-form";
import { MouseEventHandler, Suspense, useState } from "react";
import { useImagePreview, useUpload } from "../utils/hooks";
import defaultThumbnail from "../assets/background_16_9_white.svg";
import { maxFileSize } from "../utils/validation";
import Button from "./Button";
import TextInput from "./TextInput";
import { MarkdownEditorProvider } from "./MarkdownEditor/Provider";
import MarkdownEditor from "./MarkdownEditor";
import {
  MarkdownEditorContextProps,
  MarkdownValue,
} from "./MarkdownEditor/context";
import { UserAutocompleteFragment$key } from "./__generated__/UserAutocompleteFragment.graphql";
import UserAutocomplete from "./UserAutocomplete";
import EntityAvatar from "./EntityAvatar";
import { BlogArticleEditFormAuthorQuery } from "./__generated__/BlogArticleEditFormAuthorQuery.graphql";
import { MdDelete } from "react-icons/md";
import { DirtyFields } from "../utils/types";

const ICON_SIZE = 18;
const MAX_IMAGE_SIZE_MB = 1024 * 1024 * 5;

const AuthorQuery = graphql`
  query BlogArticleEditFormAuthorQuery($id: ID!) {
    node(id: $id) {
      ... on Entity {
        id
        ...EntityAvatarFragment
      }
    }
  }
`;

interface FormData {
  title: string;
  content: string;
  shortDescription: string;
  image: FileList;
  entityId: ID;
  authorsIds: ID[];
  seoTitle: string;
  seoDescription: string;
}

interface DefaultValues {
  title?: string;
  content?: string | null;
  shortDescription?: string;
  image?: string | null;
  authorsIds?: ID[] | null;
  seoTitle?: string | null;
  seoDescription?: string | null;
}

interface SubmitData {
  data: FormData;
  image: { variable: null | undefined; uploadable: File | undefined };
  setFormError: React.Dispatch<React.SetStateAction<string | undefined>>;
  dirtyFields: Partial<DirtyFields<FormData>>;
}

export interface BlogArticleEditFormProps {
  onSubmit: (data: SubmitData) => void;
  isDisabled?: boolean;
  defaultValues?: DefaultValues;
  userAutoComplete: UserAutocompleteFragment$key;
}

export default function BlogArticleEditForm(props: BlogArticleEditFormProps) {
  const intl = useIntl();
  const {
    register,
    resetField,
    watch,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm<FormData>({
    defaultValues: {
      authorsIds: props.defaultValues?.authorsIds ?? [],
    },
  });
  const [markdownValue, setMarkdownValue] = useState<MarkdownValue>(
    props.defaultValues?.content ?? "",
  );
  const image = useUpload(props.defaultValues?.image, watch("image"), () =>
    resetField("image"),
  );
  const imagePreview = useImagePreview(image.value);
  const [formError, setFormError] = useState<string | undefined>(undefined);

  const errorMessages = {
    title: {
      required: intl.formatMessage({
        defaultMessage: "Title is required",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "Title cannot be longer than 255 characters",
      }),
    },
    shortDescription: {
      required: intl.formatMessage({
        defaultMessage: "Short description is required",
      }),
      maxLength: intl.formatMessage({
        defaultMessage:
          "short description cannot be longer than 255 characters",
      }),
    },
    seoTitle: {
      maxLength: intl.formatMessage({
        defaultMessage: "Meta title cannot be longer than 255 characters",
      }),
    },
    seoDescription: {
      maxLength: intl.formatMessage({
        defaultMessage: "Meta description cannot be longer than 255 characters",
      }),
    },
    image: {
      size: intl.formatMessage({
        defaultMessage: "Image size must be at 5 MB",
      }),
    },
    content: {
      required: intl.formatMessage({
        defaultMessage: "Content is required",
      }),
    },
    authorsIds: {
      notEmpty: intl.formatMessage({
        defaultMessage: "Authors must not be empty",
      }),
    },
  };

  const contextValue: MarkdownEditorContextProps<FormData> = {
    name: "content",
    control,
    defaultValue: props.defaultValues?.content ?? "",
    setMarkdownValue,
    markdownValue,
    canUploadFiles: true,
  };

  const doSubmit = handleSubmit((data) => {
    const isAuthorsIdsDirty =
      Array.isArray(props.defaultValues?.authorsIds) &&
      JSON.stringify(data.authorsIds) !==
        JSON.stringify(props.defaultValues?.authorsIds);

    props.onSubmit({
      data,
      dirtyFields: {
        authorsIds: isAuthorsIdsDirty,
        ...dirtyFields,
      },
      image: { variable: image.variable, uploadable: image.value },
      setFormError,
    });
  });

  const onAddAuthor: React.MouseEventHandler = () => {
    const entityId = getValues("entityId");
    const currentAuthorIds = getValues("authorsIds") ?? [];
    if (currentAuthorIds.includes(entityId)) {
      return;
    }
    const updatedAuthorIds = [...currentAuthorIds, entityId];
    setValue("entityId", "");
    setValue("authorsIds", updatedAuthorIds);
  };

  const onRemoveAuthor = (authorId: ID) => {
    setValue(
      "authorsIds",
      getValues("authorsIds")?.filter((id) => id !== authorId),
    );
  };

  register("authorsIds", {
    validate: {
      notEmpty: (authorsIds) => authorsIds.length >= 1,
    },
  });

  return (
    <form onSubmit={doSubmit} className="flex flex-col gap-10">
      {formError && <p className="pt-1 text-sm text-red-500">{formError}</p>}
      <FormGroup
        label={intl.formatMessage({ defaultMessage: "BlogArticle banner" })}
        error={
          typeof errors.image?.type === "string" &&
          errorMessages.image[
            errors.image.type as keyof typeof errorMessages.image
          ]
        }
      >
        <div className="pb-4">
          <AspectDiv
            className="rounded-lg bg-grey"
            style={{
              backgroundImage: `url(${defaultThumbnail})`,
              backgroundSize: "1000px",
            }}
            ratio={4.75}
          >
            {image.isDirty
              ? imagePreview && (
                  <img
                    src={imagePreview}
                    className="h-full w-full rounded-lg object-cover object-center"
                  />
                )
              : props.defaultValues?.image && (
                  <img
                    src={props.defaultValues.image}
                    className="h-full w-full rounded-lg object-cover object-center"
                  />
                )}
          </AspectDiv>
        </div>
        <div>
          <input
            type="file"
            accept="image/png, image/jpeg"
            aria-invalid={errors.image ? "true" : "false"}
            {...register("image", {
              validate: {
                size: maxFileSize(MAX_IMAGE_SIZE_MB),
              },
            })}
          />
          <div className="flex flex-row">
            {image.isDirty && (
              <Button kind="text" onClick={image.reset}>
                <FormattedMessage defaultMessage="Reset" />
              </Button>
            )}
            {image.canDelete && (
              <Button kind="text" onClick={image.deleteImage}>
                <FormattedMessage defaultMessage="Delete" />
              </Button>
            )}
          </div>
        </div>
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({ defaultMessage: "Title" })}
        error={
          typeof errors.title?.type === "string" &&
          errorMessages.title[
            errors.title.type as keyof typeof errorMessages.title
          ]
        }
      >
        <TextInput
          aria-invalid={errors.title ? "true" : "false"}
          defaultValue={props.defaultValues?.title ?? ""}
          {...register("title", {
            setValueAs: (value) => value.trim(),
            required: true,
            maxLength: 255,
          })}
        />
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({ defaultMessage: "Short Description" })}
        error={
          typeof errors.shortDescription?.type === "string" &&
          errorMessages.shortDescription[
            errors.shortDescription
              .type as keyof typeof errorMessages.shortDescription
          ]
        }
      >
        <TextInput
          aria-invalid={errors.shortDescription ? "true" : "false"}
          defaultValue={props.defaultValues?.shortDescription}
          {...register("shortDescription", {
            setValueAs: (value) => value.trim(),
            required: true,
            maxLength: 255,
          })}
        />
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({ defaultMessage: "Seo meta title" })}
        error={
          typeof errors.seoTitle?.type === "string" &&
          errorMessages.seoTitle[
            errors.seoTitle.type as keyof typeof errorMessages.seoTitle
          ]
        }
      >
        <TextInput
          aria-invalid={errors.seoTitle ? "true" : "false"}
          defaultValue={props.defaultValues?.seoTitle ?? ""}
          {...register("seoTitle", {
            setValueAs: (value) => value.trim(),
            maxLength: 255,
          })}
        />
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({ defaultMessage: "Seo meta description" })}
        error={
          typeof errors.seoDescription?.type === "string" &&
          errorMessages.seoDescription[
            errors.seoDescription
              .type as keyof typeof errorMessages.seoDescription
          ]
        }
      >
        <TextInput
          aria-invalid={errors.seoDescription ? "true" : "false"}
          defaultValue={props.defaultValues?.seoDescription ?? ""}
          {...register("seoDescription", {
            setValueAs: (value) => value.trim(),
            maxLength: 255,
          })}
        />
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({ defaultMessage: "Authors" })}
        error={
          typeof errors.authorsIds?.type === "string" &&
          errorMessages.authorsIds[
            errors.authorsIds.type as keyof typeof errorMessages.authorsIds
          ]
        }
      >
        <div className="flex flex-col gap-8">
          <div className="flex-1 flex flex-row gap-2">
            <UserAutocomplete
              query={props.userAutoComplete}
              placeholder={intl.formatMessage({
                defaultMessage: "username",
              })}
              {...register("entityId")}
            />

            <Button
              kind="primary"
              onClick={onAddAuthor}
              disabled={props.isDisabled}
            >
              <FormattedMessage defaultMessage="Add Member" />
            </Button>
          </div>

          <Authors control={control} onRemove={onRemoveAuthor} />
        </div>
      </FormGroup>

      <MarkdownEditorProvider value={contextValue}>
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "BlogArticle" })}
          error={
            typeof errors.content?.type === "string" &&
            errorMessages.content[
              errors.content.type as keyof typeof errorMessages.content
            ]
          }
        >
          <MarkdownEditor
            rows={65}
            aria-invalid={errors.content ? "true" : "false"}
          />
        </FormGroup>
      </MarkdownEditorProvider>
      <div className="pt-2 pb-8">
        <Button kind="primary" onClick={doSubmit} disabled={props.isDisabled}>
          <FormattedMessage defaultMessage="Save" />
        </Button>
      </div>
    </form>
  );
}

const Authors = ({
  control,
  onRemove,
}: {
  control: Control<FormData>;
  onRemove: (entityId: ID) => void;
}) => {
  const authorsIds = useWatch({ control, name: "authorsIds" });

  if (authorsIds.length <= 0) {
    return null;
  }

  return (
    <div className="grid grid-cols-4">
      {authorsIds.map((authorId, index) => (
        <Suspense key={index} fallback={<EntityAvatar.Skeleton />}>
          <AuthorSelection
            authorId={authorId}
            onRemove={() => onRemove(authorId)}
          />
        </Suspense>
      ))}
    </div>
  );
};

interface AuthorSelectionProps {
  authorId: string;
  onRemove: MouseEventHandler;
}

const AuthorSelection = ({
  authorId,
  onRemove,
}: Readonly<AuthorSelectionProps>) => {
  const intl = useIntl();
  const data = useLazyLoadQuery<BlogArticleEditFormAuthorQuery>(
    AuthorQuery,
    { id: authorId },
    { fetchPolicy: "store-or-network" },
  );

  if (!data?.node) {
    return null;
  }

  return (
    <div className="relative flex items-center gap-2 group">
      <EntityAvatar entity={data.node} />

      <button
        type="button"
        onClick={onRemove}
        aria-label={intl.formatMessage({ defaultMessage: "Remove author" })}
        className="absolute top-1 right-1 p-1 text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
      >
        <MdDelete size={ICON_SIZE} />
      </button>
    </div>
  );
};
