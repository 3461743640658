/**
 * @generated SignedSource<<461ab05dd614834c41d2d56079380426>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateOrganizationInput = {
  bio?: string | null | undefined;
  displayName?: string | null | undefined;
  github?: string | null | undefined;
  googleScholar?: string | null | undefined;
  image?: any | null | undefined;
  inQuantumJob?: boolean | null | undefined;
  linkedin?: string | null | undefined;
  location?: string | null | undefined;
  username?: string | null | undefined;
  website?: any | null | undefined;
};
export type OrganizationEditFormMutation$variables = {
  id: string;
  input: UpdateOrganizationInput;
};
export type OrganizationEditFormMutation$data = {
  readonly updateOrganization: {
    readonly node: {
      readonly bio: string | null | undefined;
      readonly createdAt: string;
      readonly displayName: string;
      readonly github: string | null | undefined;
      readonly googleScholar: string | null | undefined;
      readonly id: string;
      readonly image: any | null | undefined;
      readonly imageThumbnail: any | null | undefined;
      readonly linkedin: string | null | undefined;
      readonly location: string | null | undefined;
      readonly username: string;
      readonly website: string | null | undefined;
    };
  };
};
export type OrganizationEditFormMutation = {
  response: OrganizationEditFormMutation$data;
  variables: OrganizationEditFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "OrganizationEdge",
    "kind": "LinkedField",
    "name": "updateOrganization",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "linkedin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "github",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "googleScholar",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "website",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageThumbnail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationEditFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationEditFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "940d4ba6bfd7425321beceb0663cf464",
    "id": null,
    "metadata": {},
    "name": "OrganizationEditFormMutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationEditFormMutation(\n  $id: ID!\n  $input: UpdateOrganizationInput!\n) {\n  updateOrganization(id: $id, input: $input) {\n    node {\n      id\n      displayName\n      username\n      linkedin\n      github\n      googleScholar\n      location\n      website\n      bio\n      image\n      imageThumbnail\n      createdAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bf30de4c8bc76192f1eea99c6b03b884";

export default node;
