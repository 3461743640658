import { useController } from "react-hook-form";
import { useMarkdownEditor } from "./context";
import TextAreaInput from "../TextArea";
import { TextAreaDragNDropUploadZone } from "../UploadImage/TextAreaDragNDropUploadZone";
import { TextareaHTMLAttributes, useCallback, useEffect, useRef } from "react";
import { useWrite } from "./useWrite";
import { FaMarkdown } from "react-icons/fa";
import CommandMembersSelect from "./MemberSelect";
import { Spread } from "../../utils/types";
import TurndownService from "turndown";

export type ResetTextarea = (resetFunc: () => void) => void;

export interface PropsExtra {
  resetTextarea?: ResetTextarea;
}

interface Props
  extends Spread<PropsExtra, TextareaHTMLAttributes<HTMLTextAreaElement>> {}

export const Write = ({ resetTextarea, ...rest }: Readonly<Props>) => {
  const {
    textareaRef,
    insertTextAtLine,
    onChange,
    dropdownRef,
    inputRef,
    onCommandSelect,
    commandValue,
  } = useWrite();

  const {
    name,
    control,
    defaultValue,
    members,
    canUploadFiles,
    setMarkdownValue,
  } = useMarkdownEditor();

  const {
    field: { value, onChange: onFormChange },
  } = useController({ name, control, defaultValue });

  const debouncedRefetch = useRef<((search: string) => void) | null>(null);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event, (search: string) => {
        if (members?.kind === "all" && debouncedRefetch.current) {
          debouncedRefetch.current(search);
        }
      });
      onFormChange(event);
    },
    [onChange, members, debouncedRefetch, onFormChange],
  );

  const handleOnPaste = useCallback(
    (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
      if (!event.clipboardData) {
        return;
      }

      const htmlData = event.clipboardData.getData("text/html");
      const textData = event.clipboardData.getData("text/plain");

      const isMarkdown = (text: string) => {
        const markdownRegex =
          /(^#{1,6}\s)|(\*\*|__|[*_-]{3,})|(\[.*?\]\(.*?\))|(```[\s\S]*?```)|(`.*?`)|(!\[.*?\]\(.*?\))/;
        return markdownRegex.test(text);
      };
      if (htmlData) {
        if (isMarkdown(htmlData)) {
          return;
        }
        event.preventDefault();
        const turndownService = new TurndownService();
        const markdown = turndownService.turndown(htmlData);

        if (textareaRef.current) {
          const start = textareaRef.current.selectionStart;
          const end = textareaRef.current.selectionEnd;
          const currentValue = textareaRef.current.value;
          const newValue =
            currentValue.slice(0, start) + markdown + currentValue.slice(end);
          textareaRef.current.value = newValue;
          onFormChange({
            target: { value: newValue },
          } as unknown as React.ChangeEvent<HTMLTextAreaElement>);
          setMarkdownValue(newValue);
        }
      } else if (textData) {
        handleOnChange(
          event as unknown as React.ChangeEvent<HTMLTextAreaElement>,
        );
      }
    },
    [textareaRef, setMarkdownValue, onFormChange, handleOnChange],
  );

  const commonTextAreaProps: TextareaHTMLAttributes<HTMLTextAreaElement> = {
    defaultValue: value,
    onChange: handleOnChange,
    onPaste: handleOnPaste,
    rows: 5,
    ...rest,
  };

  const memoizedResetTextarea = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.value = "";
    }
  }, [textareaRef]);

  useEffect(() => {
    if (resetTextarea) {
      resetTextarea(memoizedResetTextarea);
    }
  }, [resetTextarea, memoizedResetTextarea]);

  return (
    <div className="relative w-full">
      {canUploadFiles ? (
        <TextAreaDragNDropUploadZone
          ref={textareaRef}
          onFilesSent={insertTextAtLine}
          {...commonTextAreaProps}
        />
      ) : (
        <TextAreaInput ref={textareaRef} {...commonTextAreaProps} />
      )}
      <div className="flex gap-2 items-center mt-1">
        <FaMarkdown />
        <p className="prose-none text-sm text-gray-500">Supports markdown</p>
      </div>
      <CommandMembersSelect
        debouncedRefetch={debouncedRefetch}
        dropdownRef={dropdownRef}
        inputRef={inputRef}
        onCommandSelect={onCommandSelect}
        commandValue={commandValue}
      />
    </div>
  );
};
