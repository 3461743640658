import { graphql, Environment, useFragment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { BlogArticleEditPageQuery as BlogArticleEditPageQueryType } from "./__generated__/BlogArticleEditPageQuery.graphql";
import { BlogArticleEditPageFragment$key } from "./__generated__/BlogArticleEditPageFragment.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { BlogArticleEditPageQuery as BlogArticleEditPageQueryType } from "./__generated__/BlogArticleEditPageQuery.graphql";

export const BlogArticleEditPageFragment = graphql`
  fragment BlogArticleEditPageFragment on BlogArticle {
    id
    title
    content
    image
    shortDescription
    seoTitle
    seoDescription
    authors {
      edges {
        node {
          id
        }
      }
    }
    ...BlogArticleDeleteButtonFragment
  }
`;

export const BlogArticleEditPageQuery = graphql`
  query BlogArticleEditPageQuery($id: ID!) {
    ...UserAutocompleteFragment

    node(id: $id) {
      ... on BlogArticle {
        ...BlogArticleEditPageFragment
      }
    }
  }
`;

export const blogArticleEditPageQueryLoader = (environment: Environment) => {
  return ({ params: { id } }: LoaderArgs) => {
    if (!id) {
      throw new Response("No id", { status: 500 });
    }
    return preload<BlogArticleEditPageQueryType>(
      environment,
      BlogArticleEditPageQuery,
      {
        id,
      },
    );
  };
};

export const useBlogArticleEditPageQuery = () => {
  const { query, ...preloaded } = usePreloaded<BlogArticleEditPageQueryType>();
  const article = useFragment(
    BlogArticleEditPageFragment,
    query.node as BlogArticleEditPageFragment$key | null,
  );
  return { query, article, ...preloaded };
};
