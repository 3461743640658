import React, { useState } from "react";
import Button from "./Button";
import { FormattedMessage } from "react-intl";
import MarkdownEditor from "./MarkdownEditor";
import Markdown from "./Markdown";
import { useForm } from "react-hook-form";
import { MarkdownEditorProvider } from "./MarkdownEditor/Provider";
import type {
  ContextTypeMembers,
  MarkdownEditorContextProps,
  MarkdownValue,
} from "./MarkdownEditor/context";

interface Props {
  className?: string;
  title?: React.ReactNode;
  fallback?: React.ReactNode;
  canEdit?: boolean;
  canUploadFiles?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  members: ContextTypeMembers;
  onSubmit: (
    value: string,
    setError: React.Dispatch<React.SetStateAction<string | null>>,
    onCompleted: () => void,
  ) => void;
}

type FormData = {
  value: string;
};

export default function EditMarkdownSection({
  title,
  fallback,
  members,
  className,
  canEdit = false,
  canUploadFiles = false,
  disabled = false,
  defaultValue = "",
  onSubmit,
}: Props) {
  const { handleSubmit, control, reset } = useForm<FormData>();
  const [formError, setFormError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [markdownValue, setMarkdownValue] =
    useState<MarkdownValue>(defaultValue);

  const onUpdate = handleSubmit((data) => {
    setFormError(null);
    if (disabled) {
      return;
    }
    if (data.value === defaultValue) {
      setIsEditing(false);
      return;
    }
    onSubmit(data.value, setFormError, () => {
      setIsEditing(false);
      reset();
    });
  });

  const contextValue: MarkdownEditorContextProps<FormData> = {
    name: "value",
    defaultValue,
    control,
    setMarkdownValue,
    markdownValue,
    canUploadFiles,
    members,
  };

  return (
    <MarkdownEditorProvider value={contextValue}>
      <form>
        <div className="flex flex-row justify-between space-x-2">
          <div className="flex-grow">{title}</div>
          {canEdit &&
            (isEditing ? (
              <div className="flex flex-row flex-grow-0 space-x-2 self-center">
                <Button
                  kind="text"
                  onClick={() => setIsEditing(false)}
                  disabled={disabled}
                >
                  <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button kind="primary" disabled={disabled} onClick={onUpdate}>
                  <FormattedMessage defaultMessage="Save" />
                </Button>
              </div>
            ) : (
              <Button kind="text" onClick={() => setIsEditing(true)}>
                <FormattedMessage defaultMessage="Edit" />
              </Button>
            ))}
        </div>
        {formError && <p className="text-red-500 text-sm pb-2">{formError}</p>}
        <div className={`pb-16 ${className}`}>
          {isEditing ? (
            <MarkdownEditor rows={15} />
          ) : defaultValue ? (
            <Markdown>{defaultValue}</Markdown>
          ) : (
            fallback
          )}
        </div>
      </form>
    </MarkdownEditorProvider>
  );
}
