import React from "react";
import { createPortal } from "react-dom";
import { useWindowDimensions } from "../utils/hooks";

interface Props {
  parentRef: React.RefObject<HTMLElement>;
  children: React.ReactNode;
}

export default function DropdownList({ children, parentRef }: Props) {
  const windowDimensions = useWindowDimensions();
  if (!parentRef.current) {
    return null;
  }
  const offset = parentRef.current.getBoundingClientRect();
  return createPortal(
    <div
      className={`origin-top-right absolute mt-2 min-w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}
      style={{
        right: window.scrollX + windowDimensions.width - offset.right,
        top: window.scrollY + offset.top + offset.height,
        width: offset.width,
        pointerEvents: "auto",
      }}
    >
      <div
        className="py-1"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        {children}
      </div>
    </div>,
    document.body,
  );
}
