import { useEffect, useState } from "react";
import { TOCItem } from "../utils/generateToc";
import scrollIntoView from "scroll-into-view-if-needed";
import { cn } from "../utils/tailwind";
import { FormattedMessage } from "react-intl";

export interface Props {
  titles: TOCItem[];
  indentationFactor?: number;
  className?: string;
}

export default function TableOfContents({
  titles,
  indentationFactor = 25,
}: Props) {
  const [visibleTitles, setVisibleTitles] = useState<string[]>([]);
  const activeId: string | undefined = visibleTitles[0];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const intersecting = Object.fromEntries(
          entries.map((e) => [e.target.id, e.isIntersecting]),
        );

        setVisibleTitles((visibleTitles) => {
          const newVisibleTitles = [];
          for (const title of titles) {
            // iterate over titles to preserve order
            if (title.id in intersecting) {
              // we got notified the title either got in or out of the window
              if (intersecting[title.id]) {
                // mark title as visible only when intersecting with observer's root
                newVisibleTitles.push(title.id);
              }
            } else if (visibleTitles.includes(title.id)) {
              // we did not get notified about this title, let's keep the same visibility
              newVisibleTitles.push(title.id);
            }
          }
          return newVisibleTitles;
        });
      },
      { threshold: 1 },
    );

    for (const title of titles) {
      const element = document.getElementById(title.id);
      if (element) {
        observer.observe(element);
      }
    }

    return () => observer.disconnect();
  }, [titles]);

  const handleScroll = (id: string) => {
    const targetElement = document.getElementById(id);
    if (!targetElement) return;

    scrollIntoView(targetElement, {
      behavior: "smooth",
      scrollMode: "always",
      block: "start",
    });

    const url = new URL(location.href);
    url.hash = id;
    window.history.pushState(null, "", url);
  };

  if (!titles.length) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <p className="text-base font-semibold leading-normal text-gray-950">
        <FormattedMessage defaultMessage="Table of contents" />
      </p>
      <ul>
        {titles.map((heading) => (
          <li
            key={heading.id}
            className="mb-2"
            style={{
              marginInlineStart: getIndentation(
                heading.depth,
                indentationFactor,
              ),
            }}
          >
            <button
              onClick={() => handleScroll(heading.id)}
              className={cn(
                activeId === heading.id ? "text-indigo" : "text-gray-600",
                "text-start font-normal leading-normal",
              )}
            >
              {heading.text}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

function getIndentation(depth: number, indentationFactor: number): number {
  return (depth - 1) * indentationFactor;
}
