/* eslint-disable react-refresh/only-export-components */
import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { CompetitionNoCodeSubmissionPageQuery as CompetitionNoCodeSubmissionPageQueryType } from "./__generated__/CompetitionNoCodeSubmissionPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { CompetitionNoCodeSubmissionPageQuery as CompetitionNoCodeSubmissionPageQueryType } from "./__generated__/CompetitionNoCodeSubmissionPageQuery.graphql";

export const CompetitionNoCodeSubmissionPageQuery = graphql`
  query CompetitionNoCodeSubmissionPageQuery($slug: String!) {
    viewer @ifAllowed {
      id
      entitiesHead: entities(first: 2) {
        edges {
          node {
            id
          }
        }
      }
      ...UserEntitiesAutocompleteFragment
      ...CompetitionEntitySubmissionStatusEntityFragment
    }

    competitionBySlug(slug: $slug) {
      id
      submissionPreamble
      latestRule {
        text
        entityAgreement {
          id
        }
      }
      viewerCanAcceptRules: viewerCan(action: CREATE_COMPETITION_RULE_AGREEMENT)
      viewerCanUpdate: viewerCan(action: UPDATE_COMPETITION)
      ...CompetitionEntitySubmissionStatusCompetitionFragment
      ...CompetitionAcceptRulesButtonCompetitionFragment
      ...MemberSelectCompetitionFragment
    }
  }
`;

export const competitionNoCodeSubmissionPageQueryLoader = (
  environment: Environment,
) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<CompetitionNoCodeSubmissionPageQueryType>(
      environment,
      CompetitionNoCodeSubmissionPageQuery,
      { slug },
    );
  };
};

export const useCompetitionNoCodeSubmissionPageQuery = () =>
  usePreloaded<CompetitionNoCodeSubmissionPageQueryType>();
