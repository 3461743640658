import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { CompetitionEditPageQuery as CompetitionEditPageQueryType } from "./__generated__/CompetitionEditPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { CompetitionEditPageQuery as CompetitionEditPageQueryType } from "./__generated__/CompetitionEditPageQuery.graphql";

export const CompetitionEditPageQuery = graphql`
  query CompetitionEditPageQuery($slug: String!) {
    competitionBySlug(slug: $slug) {
      id
      slug
      title
      shortDescription
      banner
      thumbnail
      visibility
      requiresApproval
      grantHostSubmissionAccess
      hasLeaderboard
      noCode
      submissionPreamble
      tags {
        ...CompetitionTagInputForCompetitionEditPageFragment
      }

      viewerCanUpdateAccess: viewerCan(action: UPDATE_COMPETITION_ACCESS)
    }
    ...CompetitionTagInputFragment
  }
`;

export const competitionEditPageQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<CompetitionEditPageQueryType>(
      environment,
      CompetitionEditPageQuery,
      { slug },
    );
  };
};

export const useCompetitionEditPageQuery = () =>
  usePreloaded<CompetitionEditPageQueryType>();
