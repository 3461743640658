/**
 * @generated SignedSource<<8391fc3d14744a8f5808fbe220d3bc36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserInput = {
  bio?: string | null | undefined;
  displayName?: string | null | undefined;
  email?: string | null | undefined;
  github?: string | null | undefined;
  googleScholar?: string | null | undefined;
  image?: any | null | undefined;
  inQuantumJob?: boolean | null | undefined;
  jobTitle?: string | null | undefined;
  linkedin?: string | null | undefined;
  location?: string | null | undefined;
  oldPassword?: string | null | undefined;
  organization?: string | null | undefined;
  password?: string | null | undefined;
  username?: string | null | undefined;
  website?: any | null | undefined;
};
export type UserEditFormMutation$variables = {
  id: string;
  input: UpdateUserInput;
};
export type UserEditFormMutation$data = {
  readonly updateUser: {
    readonly node: {
      readonly bio: string | null | undefined;
      readonly createdAt: string;
      readonly displayName: string;
      readonly email: string;
      readonly github: string | null | undefined;
      readonly googleScholar: string | null | undefined;
      readonly id: string;
      readonly image: any | null | undefined;
      readonly imageThumbnail: any | null | undefined;
      readonly jobTitle: string | null | undefined;
      readonly linkedin: string | null | undefined;
      readonly location: string | null | undefined;
      readonly organization: string | null | undefined;
      readonly username: string;
      readonly website: string | null | undefined;
    };
  };
};
export type UserEditFormMutation = {
  response: UserEditFormMutation$data;
  variables: UserEditFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserEdge",
    "kind": "LinkedField",
    "name": "updateUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "linkedin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "github",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "googleScholar",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organization",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "website",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageThumbnail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserEditFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserEditFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "721e746cebb12412ac5a10516ce21c6b",
    "id": null,
    "metadata": {},
    "name": "UserEditFormMutation",
    "operationKind": "mutation",
    "text": "mutation UserEditFormMutation(\n  $id: ID!\n  $input: UpdateUserInput!\n) {\n  updateUser(id: $id, input: $input) {\n    node {\n      id\n      displayName\n      username\n      email\n      linkedin\n      github\n      googleScholar\n      organization\n      location\n      jobTitle\n      website\n      bio\n      image\n      imageThumbnail\n      createdAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0fe4015f67d414132ccece69315f7ee8";

export default node;
