/**
 * @generated SignedSource<<4c2e83def52bed9a256c2df7c5006db6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlogArticleAuthorsFragment$data = {
  readonly authors: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly jobTitle?: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"EntityAvatarFragment">;
      };
    }>;
  };
  readonly " $fragmentType": "BlogArticleAuthorsFragment";
};
export type BlogArticleAuthorsFragment$key = {
  readonly " $data"?: BlogArticleAuthorsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlogArticleAuthorsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlogArticleAuthorsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BlogArticleAuthorConnection",
      "kind": "LinkedField",
      "name": "authors",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EntityEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "jobTitle",
                      "storageKey": null
                    }
                  ],
                  "type": "User",
                  "abstractKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EntityAvatarFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BlogArticle",
  "abstractKey": null
};

(node as any).hash = "66aaa0cbf164b7be24c26b1167bfbef8";

export default node;
