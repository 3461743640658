/**
 * @generated SignedSource<<9edded3032b54f47791e6c244b17d0ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateOrganizationInput = {
  bio?: string | null | undefined;
  displayName: string;
  github?: string | null | undefined;
  googleScholar?: string | null | undefined;
  image?: any | null | undefined;
  linkedin?: string | null | undefined;
  location?: string | null | undefined;
  username: string;
  website?: any | null | undefined;
};
export type OrganizationCreatePageMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateOrganizationInput;
};
export type OrganizationCreatePageMutation$data = {
  readonly createOrganization: {
    readonly node: {
      readonly bio: string | null | undefined;
      readonly createdAt: string;
      readonly displayName: string;
      readonly github: string | null | undefined;
      readonly id: string;
      readonly image: any | null | undefined;
      readonly imageThumbnail: any | null | undefined;
      readonly linkedin: string | null | undefined;
      readonly location: string | null | undefined;
      readonly username: string;
      readonly website: string | null | undefined;
    };
  };
};
export type OrganizationCreatePageMutation = {
  response: OrganizationCreatePageMutation$data;
  variables: OrganizationCreatePageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": (v2/*: any*/),
  "concreteType": "OrganizationEdge",
  "kind": "LinkedField",
  "name": "createOrganization",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkedin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "github",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "location",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "website",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bio",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageThumbnail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationCreatePageMutation",
    "selections": [
      (v3/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrganizationCreatePageMutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": null,
        "handle": "prependEdge",
        "key": "",
        "kind": "LinkedHandle",
        "name": "createOrganization",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "987b78f02df7ad65a2cb8fd487d28608",
    "id": null,
    "metadata": {},
    "name": "OrganizationCreatePageMutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationCreatePageMutation(\n  $input: CreateOrganizationInput!\n) {\n  createOrganization(input: $input) {\n    node {\n      id\n      displayName\n      username\n      linkedin\n      github\n      location\n      website\n      bio\n      image\n      imageThumbnail\n      createdAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0f872687fac7ec5b6017032e88644ed";

export default node;
