import { useIntl } from "react-intl";
import { useBlogArticlePageQuery } from "./loaders/BlogArticlePage";
import ErrorPage from "./ErrorPage";
import BlogArticle from "../components/BlogArticle";
import { useExpectedLocationPath } from "../utils/hooks";
import { BlogArticleShape, getBlogArticlePath } from "../utils/routing";

export default function BlogArticlePage() {
  const intl = useIntl();
  const {
    query: { node, viewer },
    variables: { order },
  } = useBlogArticlePageQuery();

  useExpectedLocationPath(
    isBlogArticle(node) ? getBlogArticlePath(node) : null,
  );

  if (!node.id) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Article not found",
        })}
      />
    );
  }

  return (
    <BlogArticle article={node} viewer={viewer} initialCommentOrder={order} />
  );
}

function isBlogArticle(node: unknown): node is BlogArticleShape {
  return (
    typeof node === "object" && node !== null && "id" in node && "title" in node
  );
}
