import React, {
  createContext,
  forwardRef,
  useContext,
  useMemo,
  useState,
} from "react";
import * as Popover from "./Popover";
import { PopoverProps } from "@radix-ui/react-popover";
import { cn } from "../utils/tailwind";

interface DropdownContext {
  onItemClicked(): void;
}
const DropdownContext = createContext<DropdownContext | null>(null);

export interface DropdownProps extends PopoverProps {
  trigger: React.ReactElement;
  children: React.ReactNode;
  autoCloses?: boolean | undefined;
}

export function Dropdown({
  trigger,
  children,
  autoCloses = false,
  onOpenChange,
  open,
  ...rest
}: DropdownProps) {
  const [isOpen, setOpen] = useState(open);

  const handleOpenChange = (value: boolean) => {
    setOpen(value);
    onOpenChange?.(value);
  };

  const context = useMemo<DropdownContext>(
    () => ({
      onItemClicked() {
        if (autoCloses) {
          setOpen(false);
        }
      },
    }),
    [autoCloses],
  );

  if (!children) {
    return React.cloneElement(trigger, { disabled: true });
  }

  return (
    <DropdownContext.Provider value={context}>
      <Popover.Root
        open={autoCloses ? isOpen : open}
        onOpenChange={handleOpenChange}
        {...rest}
      >
        <Popover.Trigger asChild>{trigger}</Popover.Trigger>
        <Popover.Portal>
          <Popover.Content asChild>
            <div
              className="flex flex-col min-w-60"
              style={{ padding: "0.25rem" }}
            >
              <Popover.Arrow />
              {children}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </DropdownContext.Provider>
  );
}

export interface DropdownListProps {
  header?: React.ReactNode | undefined;
  children: React.ReactNode;
}

export function DropdownList({ header, children }: DropdownListProps) {
  return (
    <>
      {React.isValidElement(header) && (
        <h4 className="text-sm font-semibold px-6 py-2 pointer-events-none select-none">
          {header}
        </h4>
      )}
      <div className="flex flex-col space-y-1">{children}</div>
    </>
  );
}

export interface DropdownItemProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon?: React.ReactNode | undefined;
  iconEnd?: React.ReactNode | undefined;
  selected?: boolean | undefined;
  children: React.ReactNode;
}

export const DropdownItem = forwardRef<HTMLButtonElement, DropdownItemProps>(
  function DropdownItem(
    { icon, iconEnd, children, disabled, className, selected, ...props },
    ref,
  ) {
    return (
      <DropdownBaseItem>
        <button
          ref={ref}
          disabled={disabled}
          aria-selected={selected}
          {...props}
          className={cn(
            className,
            disabled
              ? "text-gray-500"
              : "hover:bg-gray-100 hover:text-slate-500 cursor-pointer",
            "rounded-lg px-4 py-2 flex items-center w-full gap-4",
          )}
        >
          {React.isValidElement(icon) && (
            <span className="text-xl">{icon}</span>
          )}

          <span className="pointer-events-none select-none text-start flex-grow">
            {children}
          </span>

          {React.isValidElement(iconEnd) && (
            <span className="text-xl">{iconEnd}</span>
          )}
        </button>
      </DropdownBaseItem>
    );
  },
);

export interface DropdownBaseItemChildrenProps {
  onClick?: React.MouseEventHandler;
}

export interface DropdownBaseItemProps {
  children: React.ReactElement<DropdownBaseItemChildrenProps>;
}

export const DropdownBaseItem = ({ children }: DropdownBaseItemProps) => {
  const dropdownContext = useContext(DropdownContext);
  const onClick: React.MouseEventHandler = (evt) => {
    children.props.onClick?.(evt);
    dropdownContext?.onItemClicked();
  };
  return React.cloneElement(children, { onClick });
};

Dropdown.BaseItem = DropdownBaseItem;
Dropdown.Item = DropdownItem;
Dropdown.List = DropdownList;
export default Dropdown;
