import { graphql, useMutation, ConnectionHandler } from "react-relay";
import { UploadableMap } from "relay-runtime";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  CompetitionCreatePageMutation as CompetitionCreatePageMutationType,
  CompetitionCreatePageMutation$variables,
} from "./__generated__/CompetitionCreatePageMutation.graphql";
import CompetitionEditForm from "../components/CompetitionEditForm";
import { useCompetitionCreatePage } from "./loaders/CompetitionCreatePage";
import { logger } from "../common/logger";

const CompetitionCreatePageMutation = graphql`
  mutation CompetitionCreatePageMutation(
    $input: CreateCompetitionInput!
    $connections: [ID!]!
  ) {
    createCompetition(input: $input) @prependEdge(connections: $connections) {
      node {
        id
        title
        description
        slug
        banner
        thumbnail
        isPrivate
        requiresApproval
        noCode
      }
    }
  }
`;

export default function CompetitionCreatePage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [commitMutation, isMutationInFlight] =
    useMutation<CompetitionCreatePageMutationType>(
      CompetitionCreatePageMutation,
    );

  const { query } = useCompetitionCreatePage();
  return (
    <div>
      <h1 className="text-xl font-bold pb-4 font-poppins">
        <FormattedMessage defaultMessage={"Create Competition"} />
      </h1>
      <CompetitionEditForm
        routePrefix="competitions"
        autoSlug={true}
        availableTags={query}
        canUpdateAccess // FIXME: this deserves a query but at the time of writing this we know only admins are allowed to create competitions
        onSubmit={({ data, banner, thumbnail, setFormError }) => {
          const variables: CompetitionCreatePageMutation$variables = {
            input: {
              title: data.title,
              shortDescription: data.shortDescription,
              slug: data.slug,
              visibility: data.visibility,
              requiresApproval: data.requiresApproval,
              grantHostSubmissionAccess: data.grantHostSubmissionAccess
                ? data.grantHostSubmissionAccess
                : undefined,
              hasLeaderboard: data.hasLeaderboard,
              noCode: data.noCode,
              banner: banner.variable,
              thumbnail: thumbnail.variable,
              tagIds: data.tagIds ?? undefined,
            },
            connections: [
              ConnectionHandler.getConnectionID(
                "root",
                "CompetitionsFragment_competitions",
              ),
            ],
          };
          const uploadables: UploadableMap = {};
          if (banner.uploadable) {
            uploadables["variables.input.banner"] = banner.uploadable;
          }
          if (thumbnail.uploadable) {
            uploadables["variables.input.thumbnail"] = thumbnail.uploadable;
          }
          commitMutation({
            variables,
            uploadables,
            onError: (error) => {
              logger.error(error);
              setFormError(
                intl.formatMessage({
                  defaultMessage:
                    "Could not create competiton. The competition URL may already be taken.",
                }),
              );
            },
            onCompleted: (results) => {
              navigate(`/competitions/${results.createCompetition.node.slug}`);
            },
          });
        }}
        isDisabled={isMutationInFlight}
      />
    </div>
  );
}
