import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { EntityScaffoldQuery as EntityScaffoldQueryType } from "./__generated__/EntityScaffoldQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { EntityScaffoldQuery as EntityScaffoldQueryType } from "./__generated__/EntityScaffoldQuery.graphql";

export const EntityScaffoldQuery = graphql`
  query EntityScaffoldQuery($username: String!) {
    viewer @ifAllowed {
      id
    }
    entityByUsername(username: $username) {
      id
      kind
      displayName
      location
      username
      linkedin
      github
      googleScholar
      website
      bio
      createdAt
      imageThumbnail
      ...EntityProfilePicFragment @arguments(thumbnail: false)
      ... on User {
        viewerCanEdit: viewerCan(action: UPDATE_USER)
        viewerCanConfigureNotifications: viewerCan(action: UPDATE_USER)
        organization
        jobTitle
        karma
      }
      ... on Organization {
        viewerCanEdit: viewerCan(action: UPDATE_ORGANIZATION)
      }
      ...EntityHelmetFragment
    }
  }
`;

export const entityScaffoldQueryLoader = (environment: Environment) => {
  return ({ params: { username } }: LoaderArgs) => {
    if (!username) {
      throw new Response("No username", { status: 500 });
    }
    return preload<EntityScaffoldQueryType>(environment, EntityScaffoldQuery, {
      username,
    });
  };
};

export const useEntityScaffoldQuery = () =>
  usePreloaded<EntityScaffoldQueryType>();
