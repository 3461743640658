/**
 * @generated SignedSource<<fd1a95b2ea61406e084fe37598126ae2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProjectVersionFileKind = "DATA" | "PACKAGE" | "SUBMISSION_EVALUATION" | "TEMPLATE";
export type CompetitionNoCodeSubmissionPageSubmitMutation$variables = {
  asEntity: string;
  competition: string;
  files: ReadonlyArray<any>;
};
export type CompetitionNoCodeSubmissionPageSubmitMutation$data = {
  readonly uploadNoCodeSubmissionVersion: {
    readonly node: {
      readonly files: ReadonlyArray<{
        readonly id: string;
        readonly kind: ProjectVersionFileKind;
        readonly uploadUrl: any | null | undefined;
      }>;
      readonly id: string;
    };
  };
};
export type CompetitionNoCodeSubmissionPageSubmitMutation = {
  response: CompetitionNoCodeSubmissionPageSubmitMutation$data;
  variables: CompetitionNoCodeSubmissionPageSubmitMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "asEntity"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "competition"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "files"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "asEntity",
        "variableName": "asEntity"
      },
      {
        "kind": "Variable",
        "name": "competitionId",
        "variableName": "competition"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "files",
            "variableName": "files"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "ProjectVersionEdge",
    "kind": "LinkedField",
    "name": "uploadNoCodeSubmissionVersion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectVersion",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectVersionFile",
            "kind": "LinkedField",
            "name": "files",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uploadUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionNoCodeSubmissionPageSubmitMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompetitionNoCodeSubmissionPageSubmitMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "04c87a7696a6b1f30ac522738cbf6a94",
    "id": null,
    "metadata": {},
    "name": "CompetitionNoCodeSubmissionPageSubmitMutation",
    "operationKind": "mutation",
    "text": "mutation CompetitionNoCodeSubmissionPageSubmitMutation(\n  $competition: ID!\n  $files: [Upload!]!\n  $asEntity: UsernameOrID!\n) {\n  uploadNoCodeSubmissionVersion(competitionId: $competition, input: {files: $files}, asEntity: $asEntity) {\n    node {\n      id\n      files {\n        id\n        kind\n        uploadUrl\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "deb4c03925309aa1cf984e9d41a359e4";

export default node;
