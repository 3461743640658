/**
 * @generated SignedSource<<9885db2680f28e91136aa38489e9e4be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlogArticleFragment$data = {
  readonly blurHash: string | null | undefined;
  readonly content: string | null | undefined;
  readonly createdAt: string;
  readonly firstAuthor: {
    readonly nodes: ReadonlyArray<{
      readonly username: string;
    }>;
  };
  readonly id: string;
  readonly image: any | null | undefined;
  readonly seoDescription: string | null | undefined;
  readonly seoTitle: string | null | undefined;
  readonly shortDescription: string;
  readonly title: string;
  readonly topic: {
    readonly " $fragmentSpreads": FragmentRefs<"TopicCommentsFormTopicFragment" | "TopicCommentsFragment" | "TopicHelmetFragment">;
  };
  readonly viewerCanEdit: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"BlogArticleAuthorsFragment">;
  readonly " $fragmentType": "BlogArticleFragment";
};
export type BlogArticleFragment$key = {
  readonly " $data"?: BlogArticleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlogArticleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "HOT",
      "kind": "LocalArgument",
      "name": "order"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlogArticleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blurHash",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seoTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seoDescription",
      "storageKey": null
    },
    {
      "alias": "viewerCanEdit",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "UPDATE_BLOG_ARTICLE"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"UPDATE_BLOG_ARTICLE\")"
    },
    {
      "alias": "firstAuthor",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "BlogArticleAuthorConnection",
      "kind": "LinkedField",
      "name": "authors",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "username",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "authors(first:1)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TopicCommentsFormTopicFragment"
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "order",
              "variableName": "order"
            }
          ],
          "kind": "FragmentSpread",
          "name": "TopicCommentsFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TopicHelmetFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BlogArticleAuthorsFragment"
    }
  ],
  "type": "BlogArticle",
  "abstractKey": null
};

(node as any).hash = "de4da285424e842dc47ee3afdee8b30e";

export default node;
