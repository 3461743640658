/**
 * @generated SignedSource<<75a75c8c565c39424f66f707baff7134>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectVersionFileKind = "DATA" | "PACKAGE" | "SUBMISSION_EVALUATION" | "TEMPLATE";
export type EntitySubmissionCodePageQuery$variables = {
  entity?: string | null | undefined;
  fileKind: ProjectVersionFileKind;
  slug: string;
  version?: string | null | undefined;
};
export type EntitySubmissionCodePageQuery$data = {
  readonly competitionBySlug: {
    readonly host: {
      readonly username: string;
    };
    readonly requiresApproval: boolean;
    readonly slug: string;
    readonly submission: {
      readonly entity: {
        readonly username: string;
      };
      readonly version: {
        readonly viewerCanApprove: boolean;
        readonly " $fragmentSpreads": FragmentRefs<"ApproveProjectVersionButtonFragment" | "SubmissionStatusBadgeFragment">;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"ProjectFileBrowserFragment">;
    } | null | undefined;
    readonly title: string;
  } | null | undefined;
};
export type EntitySubmissionCodePageQuery = {
  response: EntitySubmissionCodePageQuery$data;
  variables: EntitySubmissionCodePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entity"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fileKind"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "version"
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiresApproval",
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "entity",
    "variableName": "entity"
  }
],
v11 = {
  "kind": "Variable",
  "name": "kind",
  "variableName": "fileKind"
},
v12 = {
  "kind": "Variable",
  "name": "version",
  "variableName": "version"
},
v13 = [
  (v12/*: any*/)
],
v14 = {
  "alias": "viewerCanApprove",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "CREATE_PROJECT_VERSION_APPROVAL"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"CREATE_PROJECT_VERSION_APPROVAL\")"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = [
  (v15/*: any*/),
  (v7/*: any*/),
  (v16/*: any*/)
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EntitySubmissionCodePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "host",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          (v9/*: any*/),
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "Submission",
            "kind": "LinkedField",
            "name": "submission",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "entity",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "args": [
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "kind": "FragmentSpread",
                "name": "ProjectFileBrowserFragment"
              },
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "ProjectVersion",
                "kind": "LinkedField",
                "name": "version",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ApproveProjectVersionButtonFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SubmissionStatusBadgeFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "EntitySubmissionCodePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "host",
            "plural": false,
            "selections": (v17/*: any*/),
            "storageKey": null
          },
          (v9/*: any*/),
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "Submission",
            "kind": "LinkedField",
            "name": "submission",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "entity",
                "plural": false,
                "selections": (v17/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "ProjectVersion",
                "kind": "LinkedField",
                "name": "version",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectVersionApproval",
                    "kind": "LinkedField",
                    "name": "approval",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "version",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectVersionEvaluation",
                    "kind": "LinkedField",
                    "name": "evaluation",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "score",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "error",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "finalizedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "max",
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Competition",
                        "kind": "LinkedField",
                        "name": "competition",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasLeaderboard",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "noCode",
                            "storageKey": null
                          },
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v16/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": (v13/*: any*/),
                    "concreteType": "ProjectVersion",
                    "kind": "LinkedField",
                    "name": "version",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectVersionFile",
                        "kind": "LinkedField",
                        "name": "files",
                        "plural": true,
                        "selections": [
                          (v16/*: any*/),
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          (v11/*: any*/)
                        ],
                        "concreteType": "ProjectVersionFile",
                        "kind": "LinkedField",
                        "name": "fileByKind",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          (v18/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "extension",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectVersion",
                            "kind": "LinkedField",
                            "name": "projectVersion",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "project",
                                "plural": false,
                                "selections": [
                                  (v15/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Competition",
                                    "kind": "LinkedField",
                                    "name": "competition",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      (v16/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v16/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v16/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Project",
                "abstractKey": "__isProject"
              }
            ],
            "storageKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d4ae1f796bfa4b26a824c139cf39bba",
    "id": null,
    "metadata": {},
    "name": "EntitySubmissionCodePageQuery",
    "operationKind": "query",
    "text": "query EntitySubmissionCodePageQuery(\n  $entity: UsernameOrID\n  $slug: String!\n  $version: Semver\n  $fileKind: ProjectVersionFileKind!\n) {\n  competitionBySlug(slug: $slug) {\n    slug\n    title\n    host {\n      __typename\n      username\n      id\n    }\n    requiresApproval\n    submission(entity: $entity) {\n      entity {\n        __typename\n        username\n        id\n      }\n      ...ProjectFileBrowserFragment_z5j0a\n      version(version: $version) {\n        viewerCanApprove: viewerCan(action: CREATE_PROJECT_VERSION_APPROVAL)\n        ...ApproveProjectVersionButtonFragment\n        ...SubmissionStatusBadgeFragment\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment ApproveProjectVersionButtonFragment on ProjectVersion {\n  id\n  approval {\n    id\n  }\n  ...SubmissionStatusBadgeFragment\n}\n\nfragment ProjectDownloadButtonFragment on ProjectVersionFile {\n  id\n  kind\n  extension\n  projectVersion {\n    project {\n      __typename\n      competition {\n        slug\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment ProjectFileBrowserFragment_z5j0a on Project {\n  __isProject: __typename\n  __typename\n  id\n  version(version: $version) {\n    version\n    files {\n      id\n      kind\n    }\n    fileByKind(kind: $fileKind) {\n      id\n      kind\n      ...ProjectDownloadButtonFragment\n    }\n    id\n  }\n}\n\nfragment SubmissionStatusBadgeFragment on ProjectVersion {\n  id\n  status\n  version\n  evaluation {\n    score\n    error\n    finalizedAt\n    max\n    id\n  }\n  project {\n    __typename\n    competition {\n      hasLeaderboard\n      noCode\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "10eaa5a10ef6ef46262e74e069b88656";

export default node;
