import * as Command from "../Command";
import { graphql } from "relay-runtime";
import { useFragment, useRefetchableFragment } from "react-relay";
import { MemberSelectEntityFragment$key } from "./__generated__/MemberSelectEntityFragment.graphql";
import { MemberSelectCompetitionFragment$key } from "./__generated__/MemberSelectCompetitionFragment.graphql";
import { MemberSelectEventFragment$key } from "./__generated__/MemberSelectEventFragment.graphql";
import { MemberSelectAllEntitiesFragment$key } from "./__generated__/MemberSelectAllEntitiesFragment.graphql";
import { ContextTypeMembers, useMarkdownEditor } from "./context";
import { MemberSelectAllEntitiesAutoCompleteFragment$key } from "./__generated__/MemberSelectAllEntitiesAutoCompleteFragment.graphql";
import { RefObject, Suspense, useEffect } from "react";
import { debounce } from "lodash";
import { cn } from "../../utils/tailwind";
import { assertNever } from "../../utils/helpers";
import { Skeleton } from "../Skeleton";

const MemberSelectEntityFragment = graphql`
  fragment MemberSelectEntityFragment on Entity {
    id
    username
    displayName
  }
`;

const MemberSelectCompetitionFragment = graphql`
  fragment MemberSelectCompetitionFragment on Competition {
    textareaMembers: members {
      edges {
        node {
          entity {
            id
            ...MemberSelectEntityFragment
          }
        }
      }
    }
  }
`;

const MemberSelectEventFragment = graphql`
  fragment MemberSelectEventFragment on Event {
    textareaMembers: members {
      edges {
        node {
          entity {
            id
            ...MemberSelectEntityFragment
          }
        }
      }
    }
  }
`;

const MemberSelectAllEntitiesFragment = graphql`
  fragment MemberSelectAllEntitiesFragment on EntityConnection {
    edges {
      node {
        id
        ...MemberSelectEntityFragment
      }
    }
  }
`;

const MemberSelectAllEntitiesAutoCompleteFragment = graphql`
  fragment MemberSelectAllEntitiesAutoCompleteFragment on Query
  @refetchable(queryName: "MemberSelectAllMembersAutoCompleteRefetchQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 10 }
    search: { type: "String" }
  ) {
    entities(first: $count, search: $search) {
      ...MemberSelectAllEntitiesFragment
    }
  }
`;

interface Props {
  onCommandSelect: (value: string) => void;
}

interface MemberSelectCompetitionProps extends Props {
  members: MemberSelectCompetitionFragment$key;
}

const MemberSelectCompetition = ({
  members,
  onCommandSelect,
}: MemberSelectCompetitionProps) => {
  const data = useFragment(MemberSelectCompetitionFragment, members);

  return (
    <>
      {data.textareaMembers.edges.map((member) => (
        <CommandEntityItem
          key={member.node.entity.id}
          entity={member.node.entity}
          onCommandSelect={onCommandSelect}
        />
      ))}
    </>
  );
};

interface MemberSelectEventProps extends Props {
  members: MemberSelectEventFragment$key;
}

const MemberSelectEvent = ({
  members,
  onCommandSelect,
}: MemberSelectEventProps) => {
  const data = useFragment(MemberSelectEventFragment, members);
  return (
    <>
      {data.textareaMembers.edges.map((member) => (
        <CommandEntityItem
          key={member.node.entity.id}
          entity={member.node.entity}
          onCommandSelect={onCommandSelect}
        />
      ))}
    </>
  );
};

type DebouncedRefetch = React.MutableRefObject<
  ((search: string) => void) | null
>;

interface MemberSelectAllMembersAutoCompleteProps extends Props {
  members: MemberSelectAllEntitiesAutoCompleteFragment$key;
  debouncedRefetch: DebouncedRefetch;
}

const MemberSelectAllMembersAutoComplete = ({
  members: membersFragment,
  onCommandSelect,
  debouncedRefetch,
}: MemberSelectAllMembersAutoCompleteProps) => {
  const [allMembers, refetch] = useRefetchableFragment(
    MemberSelectAllEntitiesAutoCompleteFragment,
    membersFragment,
  );

  useEffect(() => {
    const debounced = debounce((search: string) => {
      refetch({ search: search.substring(1) });
    }, 300);
    debouncedRefetch.current = debounced;

    return () => {
      debounced.cancel();
    };
    // eslint-disable-next-line
  }, [refetch]);

  return (
    <MemberSelectAll
      members={allMembers.entities}
      onCommandSelect={onCommandSelect}
    />
  );
};

interface MemberSelectAllProps extends Props {
  members: MemberSelectAllEntitiesFragment$key;
}

const MemberSelectAll = ({
  members,
  onCommandSelect,
}: MemberSelectAllProps) => {
  const data = useFragment(MemberSelectAllEntitiesFragment, members);
  return (
    <>
      {data.edges.map((member) => (
        <CommandEntityItem
          key={member.node.id}
          entity={member.node}
          onCommandSelect={onCommandSelect}
        />
      ))}
    </>
  );
};

interface CommandEntityItemProps extends Props {
  entity: MemberSelectEntityFragment$key;
}

const CommandEntityItem = ({
  entity,
  onCommandSelect,
}: CommandEntityItemProps) => {
  const { id, username, displayName } = useFragment(
    MemberSelectEntityFragment,
    entity,
  );

  return (
    <Command.Item
      key={id}
      value={`@${username} ${displayName}`}
      onSelect={onCommandSelect}
      className="flex gap-2"
    >
      <strong>{username}</strong> <p>{displayName}</p>
    </Command.Item>
  );
};

interface MemberSelectProps extends Props {
  members?: ContextTypeMembers;
  debouncedRefetch: DebouncedRefetch;
}

function MemberSelect({
  members,
  onCommandSelect,
  debouncedRefetch,
}: MemberSelectProps) {
  if (!members || members.kind == "none") {
    return null;
  }
  const { kind, members: membersFragment } = members;

  switch (kind) {
    case "competition":
      return (
        <MemberSelectCompetition
          members={membersFragment}
          onCommandSelect={onCommandSelect}
        />
      );
    case "event":
      return (
        <MemberSelectEvent
          members={membersFragment}
          onCommandSelect={onCommandSelect}
        />
      );
    case "all":
      return (
        <MemberSelectAllMembersAutoComplete
          members={membersFragment}
          onCommandSelect={onCommandSelect}
          debouncedRefetch={debouncedRefetch}
        />
      );
    default:
      assertNever(kind);
  }
}

export interface CommandMembersSelectProps extends Props {
  debouncedRefetch: DebouncedRefetch;
  dropdownRef: RefObject<HTMLDivElement>;
  inputRef: RefObject<HTMLInputElement>;
  commandValue: string;
}

export default function CommandMembersSelect({
  debouncedRefetch,
  dropdownRef,
  inputRef,
  onCommandSelect,
  commandValue,
}: CommandMembersSelectProps) {
  const { members } = useMarkdownEditor();

  return (
    <Command.Root
      ref={dropdownRef}
      className={cn(
        "absolute hidden h-auto max-h-32 max-w-fit overflow-y-scroll border border-popover shadow",
      )}
    >
      <Suspense fallback={<CommandMembersSelectSkeleton />}>
        <div className="hidden">
          <Command.Input ref={inputRef} value={commandValue} />
        </div>
        <Command.List>
          <Command.Group className="max-w-fit overflow-auto">
            <MemberSelect
              members={members}
              onCommandSelect={onCommandSelect}
              debouncedRefetch={debouncedRefetch}
            />
          </Command.Group>
        </Command.List>
      </Suspense>
    </Command.Root>
  );
}

function CommandMembersSelectSkeleton() {
  return (
    <div className="p-2">
      <div className="space-y-2 max-w-fit overflow-auto">
        {[...Array(3)].map((_, index) => (
          <div key={index} className="flex items-center space-x-2">
            <Skeleton className="h-6 w-6 rounded-full" />
            <Skeleton className="h-4 w-24 rounded-md" />
          </div>
        ))}
      </div>
    </div>
  );
}
