import { useState } from "react";
import { graphql, useMutation } from "react-relay";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { SendPasswordResetPageMutation as SendPasswordResetPageMutationType } from "./__generated__/SendPasswordResetPageMutation.graphql";
import Button from "../components/Button";
import FormGroup from "../components/FormGroup";
import TextInput from "../components/TextInput";
import Helmet from "../components/Helmet";

const SendPasswordResetPageMutation = graphql`
  mutation SendPasswordResetPageMutation($input: CreatePasswordResetInput!) {
    createPasswordReset(input: $input)
  }
`;

type FormData = {
  usernameOrEmail: string;
};

export default function SendPasswordResetPage() {
  const navigate = useNavigate();
  const intl = useIntl();
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [formSuccess, setFormSuccess] = useState(false);
  const [commitMutation, isMutationInFlight] =
    useMutation<SendPasswordResetPageMutationType>(
      SendPasswordResetPageMutation,
    );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const onSubmit = handleSubmit((data) => {
    setFormError(undefined);
    commitMutation({
      variables: {
        input: data,
      },
      onError() {
        setFormError(
          intl.formatMessage({
            defaultMessage:
              "Could not send password reset. Check your username or email and try again.",
          }),
        );
      },
      onCompleted() {
        setFormSuccess(true);
      },
    });
  });
  const errorMessages = {
    required: intl.formatMessage({
      defaultMessage: "Username or email is required",
    }),
  };
  const metaExclude = (
    <Helmet meta={[{ name: "robots", content: "noindex, nofollow" }]} />
  );
  if (formSuccess) {
    return (
      <div className="flex flex-col space-y-3">
        {metaExclude}
        <p className="text-green-500">
          <FormattedMessage defaultMessage="If your username or email was correct you should receive an email with more instructions in a few moments!" />
        </p>
        <div className="py-3 flex justify-between items-center">
          <a
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="text-gray-600 hover:text-gray-700 hover:cursor-pointer"
          >
            <FormattedMessage defaultMessage="Go back" />
          </a>
        </div>
      </div>
    );
  }
  return (
    <form onSubmit={onSubmit}>
      {metaExclude}
      <div className="flex flex-col space-y-3">
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "Username or Email" })}
          error={
            typeof errors.usernameOrEmail?.type === "string" &&
            errorMessages[
              errors.usernameOrEmail.type as keyof typeof errorMessages
            ]
          }
        >
          <TextInput
            aria-invalid={errors.usernameOrEmail ? "true" : "false"}
            {...register("usernameOrEmail", {
              required: true,
            })}
          />
        </FormGroup>
        {formError && <p className="pt-1 text-sm text-red-500">{formError}</p>}
        <div className="py-3 flex justify-between items-center">
          <a
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="text-gray-600 hover:text-gray-700 hover:cursor-pointer"
          >
            <FormattedMessage defaultMessage="Go back" />
          </a>
          <Button type="submit" disabled={isMutationInFlight}>
            <FormattedMessage defaultMessage="Reset Password" />
          </Button>
        </div>
      </div>
    </form>
  );
}
