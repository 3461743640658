import React from "react";
import { graphql, useMutation } from "react-relay";
import { EventRulesPageUpdateEventMutation as EventRulesPageUpdateEventMutationType } from "./__generated__/EventRulesPageUpdateEventMutation.graphql";
import { useEventRulesPageQuery } from "./loaders/EventRulesPage";
import ErrorPage from "./ErrorPage";
import { useIntl, FormattedMessage } from "react-intl";
import EditMarkdownSection from "../components/EditMarkdownSection";
import EventAcceptRulesButton from "../components/EventAcceptRulesButton";
import Loading from "../components/Loading";
import { logger } from "../common/logger";

const EventRulesPageUpdateEventMutation = graphql`
  mutation EventRulesPageUpdateEventMutation(
    $id: ID!
    $input: UpdateEventInput!
  ) {
    updateEvent(id: $id, input: $input) {
      node {
        id
        latestRule {
          id
          text
          entityAgreement {
            id
            createdAt
          }
        }
      }
    }
  }
`;

export default function EventRulesPage() {
  const intl = useIntl();
  const { query } = useEventRulesPageQuery();
  const [commitMutation, isMutationInFlight] =
    useMutation<EventRulesPageUpdateEventMutationType>(
      EventRulesPageUpdateEventMutation,
    );
  if (!query.eventBySlug) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Event not found",
        })}
      />
    );
  }
  const event = query.eventBySlug;
  return (
    <EditMarkdownSection
      canUploadFiles
      members={{
        kind: "event",
        members: event,
      }}
      title={
        <div className="flex flex-row space-x-5">
          <div className="flex-grow">
            <h1 className="text-2xl font-bold w-full font-poppins">
              <FormattedMessage defaultMessage="Rules" />
            </h1>
            <p className="py-2">
              <FormattedMessage defaultMessage="By default all events must comply to the rules defined in" />{" "}
              <a
                href="https://aqora.io/terms"
                target="_blank"
                rel="noreferrer"
                className="text-blue-400"
              >
                <FormattedMessage defaultMessage="aqora's Terms of Use" />
              </a>
            </p>
          </div>
          <div className="self-center">
            {query.viewer && event.viewerCanAccept && (
              <React.Suspense fallback={<Loading className="w-10" />}>
                <EventAcceptRulesButton event={event} />
              </React.Suspense>
            )}
          </div>
        </div>
      }
      canEdit={event.viewerCanUpdate}
      disabled={isMutationInFlight}
      defaultValue={event.latestRule.text}
      onSubmit={(value, setError, onCompleted) => {
        commitMutation({
          variables: {
            id: event.id,
            input: {
              rules: value || null,
            },
          },
          onError: (error) => {
            logger.error(error);
            setError(
              intl.formatMessage({
                defaultMessage:
                  "An error occurred while saving the description. Please, try again later.",
              }),
            );
          },
          onCompleted,
        });
      }}
    />
  );
}
