/**
 * @generated SignedSource<<9fde9cc321795d0e1304b87a4d5d03ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendEmailSignupVerificationInput = {
  agreeToTermsAndPrivacyPolicy: boolean;
  email: string;
  password: string;
};
export type SignupPageEmailMutation$variables = {
  input: SendEmailSignupVerificationInput;
};
export type SignupPageEmailMutation$data = {
  readonly sendEmailSignupVerification: {
    readonly id: string;
  };
};
export type SignupPageEmailMutation = {
  response: SignupPageEmailMutation$data;
  variables: SignupPageEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EmailSignupRequest",
    "kind": "LinkedField",
    "name": "sendEmailSignupVerification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignupPageEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignupPageEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d39d9b9e5dac48b4d2ee442fb20dd5a4",
    "id": null,
    "metadata": {},
    "name": "SignupPageEmailMutation",
    "operationKind": "mutation",
    "text": "mutation SignupPageEmailMutation(\n  $input: SendEmailSignupVerificationInput!\n) {\n  sendEmailSignupVerification(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6a9047685a0b27ad582b92af1026d10d";

export default node;
