import { useState, forwardRef, ForwardedRef, useRef } from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import { fetchQuery } from "relay-runtime";
import { useIntl } from "react-intl";
import { toast } from "sonner";
import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";

import { useLocation } from "../utils/location";
import { isNotBannedUsername } from "../utils/validation";

import Loading from "./Loading";
import TextInput from "./TextInput";

import { CheckUsernameInputQuery as CheckUsernameInputQueryType } from "./__generated__/CheckUsernameInputQuery.graphql";
import { relayErrorMessage } from "../utils/relay";
import { useCache } from "../utils/hooks";

const CheckUsernameInputQuery = graphql`
  query CheckUsernameInputQuery($username: String!) {
    entityByUsername(username: $username) {
      id
    }
  }
`;

interface Props<TFieldValues extends FieldValues>
  extends UseControllerProps<TFieldValues> {}

const CheckUsernameInput = forwardRef(
  <TFieldValues extends FieldValues>(
    { name, control, defaultValue, rules }: Props<TFieldValues>,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const location = useLocation();
    const relayEnvironment = useRelayEnvironment();
    const intl = useIntl();
    const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const usernameCache = useCache<string, boolean>();
    const { field } = useController<TFieldValues>({
      name,
      control,
      defaultValue,
      rules: {
        minLength: 3,
        maxLength: 20,
        pattern: /^[a-zA-Z0-9_]*$/,
        validate: {
          isNotBannedUsername: isNotBannedUsername([]),
          isNotTaken: async (value) => {
            const cached = usernameCache.get(value);
            if (cached !== undefined) {
              return cached;
            }
            setIsLoading(true);
            await new Promise<void>((resolve) => {
              timeout.current = setTimeout(() => {
                resolve();
              }, 500);
            });
            try {
              const query = await fetchQuery<CheckUsernameInputQueryType>(
                relayEnvironment,
                CheckUsernameInputQuery,
                { username: value },
                { fetchPolicy: "store-or-network" },
              ).toPromise();
              if (!query) {
                throw Error(
                  intl.formatMessage({
                    defaultMessage: "No response received",
                  }),
                );
              }
              if (query.entityByUsername) {
                usernameCache.set(value, false);
                return false;
              } else {
                usernameCache.set(value, true);
                return true;
              }
            } catch (error) {
              toast.error(
                intl.formatMessage(
                  { defaultMessage: "Could not check username: {reason}" },
                  {
                    reason:
                      error instanceof Error
                        ? relayErrorMessage(error)
                        : "Unknown",
                  },
                ),
              );
            } finally {
              setIsLoading(false);
            }
            return true;
          },
        },
        ...rules,
      },
    });
    const [value, setValue] = useState<string>(field.value);

    return (
      <TextInput
        prefix={location.host + "/"}
        suffix={isLoading ? <Loading className="w-4" /> : undefined}
        value={value}
        onBlur={field.onBlur}
        onChange={(event) => {
          if (timeout.current) {
            clearTimeout(timeout.current);
            setIsLoading(false);
          }
          const newValue = event.target.value.trim();
          field.onChange(newValue);
          setValue(newValue);
        }}
        disabled={field.disabled}
        ref={ref}
      />
    );
  },
);

export default CheckUsernameInput;
