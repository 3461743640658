import { graphql, useFragment } from "react-relay";
import { TopicLinkFragment$key } from "./__generated__/TopicLinkFragment.graphql";
import { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { getTopicPath } from "../utils/routing";
import { useContext } from "react";
import { TopicVotableOrderContext } from "../utils/votableOrder";

const TopicLinkFragment = graphql`
  fragment TopicLinkFragment on Topic {
    id
    title
    forum {
      slug
      owner {
        slug
        forumOwnerKind
      }
    }
  }
`;

export interface TopicLinkProps extends Omit<LinkProps, "to"> {
  topic: TopicLinkFragment$key;
  subpath?: string | undefined;
}

export default function TopicLink({
  topic: topicFragment,
  subpath,
  ...props
}: TopicLinkProps) {
  const topic = useFragment(TopicLinkFragment, topicFragment);
  const order = useContext(TopicVotableOrderContext);
  return (
    <Link
      {...props}
      to={subpath ? getTopicPath(topic, subpath) : getTopicPath(topic)}
      state={{ order }}
      rel="canonical"
    />
  );
}
