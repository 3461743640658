import { graphql, useFragment } from "react-relay";
import { BlogArticleFragment$key } from "./__generated__/BlogArticleFragment.graphql";
import { extractMarkdownTitles } from "../utils/generateToc";
import {
  FormattedDate,
  FormattedMessage,
  defineMessage,
  defineMessages,
  useIntl,
} from "react-intl";
import TopicHelmet from "./TopicHelmet";
import { Suspense, useMemo } from "react";
import { Link } from "react-router-dom";
import LazyImage from "./LazyImage";
import TableOfContents from "./TableOfContents";
import BlogArticleAuthors from "./BlogArticleAuthors";
import Markdown from "./Markdown";
import { useMediaQuery, useVotableOrder } from "../utils/hooks";
import { DESKTOP_MEDIA_QUERY } from "../common/constants";
import TopicCommentsForm from "./TopicCommentsForm";
import TopicComments from "./TopicComments";
import { TopicCommentsFormViewerFragment$key } from "./__generated__/TopicCommentsFormViewerFragment.graphql";
import { getBlogArticlePath } from "../utils/routing";
import { TopicVotableOrderContext, VotableOrder } from "../utils/votableOrder";
import VotableOrderSelect from "./VotableOrderSelect";
import SignupCallout from "./SignupCallout";

const Fragment = graphql`
  fragment BlogArticleFragment on BlogArticle
  @argumentDefinitions(order: { type: "VotableOrder", defaultValue: HOT }) {
    id
    title
    content
    createdAt
    shortDescription
    image
    blurHash
    seoTitle
    seoDescription
    viewerCanEdit: viewerCan(action: UPDATE_BLOG_ARTICLE)
    firstAuthor: authors(first: 1) {
      nodes {
        username
      }
    }
    topic {
      ...TopicCommentsFormTopicFragment
      ...TopicCommentsFragment @arguments(order: $order)
      ...TopicHelmetFragment
    }
    ...BlogArticleAuthorsFragment
  }
`;

export interface BlogArticleProps {
  article: BlogArticleFragment$key;
  viewer: TopicCommentsFormViewerFragment$key | null;
  initialCommentOrder: VotableOrder;
}

export default function BlogArticle(props: BlogArticleProps) {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const intl = useIntl();
  const article = useFragment(Fragment, props.article);
  const articleTitles = useMemo(
    () => extractMarkdownTitles(article.content ?? ""),
    [article.content],
  );
  const [order, setOrder] = useVotableOrder(props.initialCommentOrder);

  return (
    <TopicVotableOrderContext.Provider value={order}>
      <TopicHelmet
        topic={article.topic}
        metaTitle={article.seoTitle}
        metaDescription={article.seoDescription ?? article.shortDescription}
        metaImageAlt={intl.formatMessage(imageAlt, {
          title: article.seoTitle ?? article.title,
        })}
        metaImageUrl={article.image}
      />

      <article className="flex flex-col items-start gap-2">
        <header className="flex flex-row justify-between w-full">
          <div className="flex flex-col gap-2">
            <p className="text-indigo text-sm font-semibold leading-tight">
              <FormattedMessage
                defaultMessage="{authorName} • {date}"
                values={{
                  authorName: article.firstAuthor.nodes[0].username,
                  date: (
                    <FormattedDate
                      dateStyle="medium"
                      value={article.createdAt}
                    />
                  ),
                }}
              />
            </p>

            <h1 className="text-gray-950 text-3xl font-semibold leading-10 -ml-1">
              {article.title}
            </h1>
          </div>

          {article.viewerCanEdit && (
            <Link to={getBlogArticlePath(article, "edit")}>
              <FormattedMessage defaultMessage="Edit" />
            </Link>
          )}
        </header>

        <figure className="w-full h-full rounded-xl overflow-hidden border border-gray-300/75 my-12">
          <LazyImage
            ratio={19 / 9}
            blurHash={article.blurHash}
            className="relative"
            image={{
              src: article.image,
              className: "h-full w-full object-cover object-center",
              alt: intl.formatMessage({ defaultMessage: "Article image" }),
            }}
          />

          <figcaption className="px-4 py-2 text-gray-400 text-xs">
            <FormattedMessage
              {...imageAlt}
              values={{ title: article.seoTitle ?? article.title }}
            />
          </figcaption>
        </figure>

        <div className="flex gap-8 flex-col-reverse xl:flex-row">
          <aside className="flex flex-col sticky top-4 h-max gap-8 xl:w-[33%]">
            {isDesktop && <TableOfContents titles={articleTitles} />}
            <hr className="text-gray-200" />
            <BlogArticleAuthors article={article} />
            <hr className="text-gray-200" />
          </aside>

          <section className="flex flex-col gap-8 xl:w-[67%]">
            <Markdown>{article.content}</Markdown>
          </section>
        </div>

        <footer className="py-5 w-full mt-12">
          <TopicCommentsForm
            topic={article.topic}
            viewer={props.viewer}
            order={order}
          />

          <div>
            <FormattedMessage defaultMessage="Order by:" />{" "}
            <VotableOrderSelect value={order} onChange={setOrder} />
          </div>

          <Suspense fallback={<TopicComments.Skeleton />}>
            <TopicComments topic={article.topic} order={order} hidePermalink />
          </Suspense>

          <SignupCallout {...cta} className="sm:w-2/3 m-auto mt-12" />
        </footer>
      </article>
    </TopicVotableOrderContext.Provider>
  );
}

const imageAlt = defineMessage({
  defaultMessage: "Article image for {title}",
});

const cta = defineMessages({
  title: {
    defaultMessage: `Want to join this discussion?`,
  },
  body: {
    defaultMessage: `Join our community today and start discussing with our members by participating in exciting events, competitions, and challenges. Sign up now to engage with quantum experts!`,
  },
});
