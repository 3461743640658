import { ConnectionHandler, graphql, useMutation } from "react-relay";
import { EventCompetitionsFragment$key } from "./__generated__/EventCompetitionsFragment.graphql";
import { EventCompetitionsDeleteMutation as EventCompetitionsDeleteMutationType } from "./__generated__/EventCompetitionsDeleteMutation.graphql";
import { usePaginationFragment } from "react-relay";
import { FormattedMessage } from "react-intl";
import { MdMoreHoriz } from "react-icons/md";
import Dropdown from "./Dropdown";
import { useAuth } from "../utils/auth";
import LoadMore from "./LoadMore";
import { Link } from "react-router-dom";
import { ToolbarButton } from "./Button";
import { logger } from "../common/logger";

const EventCompetitionsFragment = graphql`
  fragment EventCompetitionsFragment on Event
  @refetchable(queryName: "EventCompetitionsFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    id
    competitions(first: $count, after: $cursor)
      @connection(key: "EventCompetitionsFragment_competitions") {
      edges {
        node {
          id
          viewerCanDelete: viewerCan(action: REMOVE_EVENT_COMPETITION)
          competition {
            id
            slug
            title
            ...CompetitionCardFragment
          }
        }
      }
    }
  }
`;

const EventCompetitionsDeleteMutation = graphql`
  mutation EventCompetitionsDeleteMutation($id: ID!, $connections: [ID!]!) {
    removeEventCompetition(id: $id) @deleteEdge(connections: $connections)
  }
`;

interface Props {
  event: EventCompetitionsFragment$key;
}

export default function EventCompetitions({ event: eventFragment }: Props) {
  const { userId: viewerId } = useAuth();
  const {
    data: event,
    refetch,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(EventCompetitionsFragment, eventFragment);
  const [commitDeleteMutation, isDeleteMutationInFlight] =
    useMutation<EventCompetitionsDeleteMutationType>(
      EventCompetitionsDeleteMutation,
    );
  if (event.competitions.edges.length == 0) {
    return (
      <p className="text-gray-400">
        <FormattedMessage defaultMessage="No competitions" />
      </p>
    );
  }
  const onDelete = (id: string, userId: string) => {
    commitDeleteMutation({
      variables: {
        id,
        connections: [
          ConnectionHandler.getConnectionID(
            event.id,
            "EventCompetitionsFragment_competitions",
          ),
          ConnectionHandler.getConnectionID(
            event.id,
            "EventCompetitionsCarouselFragment_competitions",
          ),
        ],
      },
      onError: (error) => {
        logger.error(error);
      },
      onCompleted: () => {
        if (userId === viewerId) {
          refetch({});
        }
      },
    });
  };
  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="Competition" />
            </th>
            <th className="py-3 px-3 bg-gray-100"></th>
          </tr>
        </thead>
        <tbody>
          {event.competitions.edges.map(
            ({ node: { id, viewerCanDelete, competition } }) => (
              <tr key={id}>
                <td className="whitespace-nowrap py-2 px-3">
                  <Link to={`/competitions/${competition.slug}`}>
                    <div className="flex flex-row items-center hover:cursor-pointer">
                      <span>
                        <strong>{competition.slug}</strong>: {competition.title}
                      </span>
                    </div>
                  </Link>
                </td>
                <td className="whitespace-nowrap py-2 px-3 text-center">
                  {viewerCanDelete && (
                    <Dropdown
                      autoCloses
                      trigger={
                        <ToolbarButton size="sm">
                          <MdMoreHoriz />
                        </ToolbarButton>
                      }
                    >
                      <Dropdown.Item
                        disabled={isDeleteMutationInFlight}
                        onClick={() => {
                          onDelete(id, competition.id);
                        }}
                      >
                        <FormattedMessage defaultMessage="Remove competition" />
                      </Dropdown.Item>
                    </Dropdown>
                  )}
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
      <div className="py-8">
        <LoadMore
          loadMore={loadNext}
          hasMore={hasNext}
          isLoading={isLoadingNext}
        />
      </div>
    </div>
  );
}
