import { graphql } from "relay-runtime";
import {
  EntityHelmetFragment$data,
  EntityHelmetFragment$key,
} from "./__generated__/EntityHelmetFragment.graphql";
import { useFragment } from "react-relay";
import Helmet from "./Helmet";
import { LocationContextValue, useLocation } from "../utils/location";
import { MetaLayout } from "../common/MetaLayout";
import { useProfileMetaTags } from "../utils/useMetaTags";
import { addIfDefined } from "../utils/helpers";
import { JsonValue, SafeScript } from "./SafeScript";

const Fragment = graphql`
  fragment EntityHelmetFragment on Entity {
    id
    kind
    displayName
    location
    username
    linkedin
    github
    googleScholar
    website
    bio
    createdAt
    image
    imageThumbnail
    ... on User {
      jobTitle
      comments(last: 10) {
        nodes {
          content
          createdAt
          votes
        }
      }
    }
  }
`;

export interface EntityHelmetProps {
  entity: EntityHelmetFragment$key;
}

export default function EntityHelmet({
  entity: entityFragment,
}: EntityHelmetProps) {
  const entity = useFragment(Fragment, entityFragment);
  const { metaTitle, metaDescription } = useProfileMetaTags(
    entity.displayName,
    entity.kind,
    entity.jobTitle,
  );
  const location = useLocation();
  const metaImageAlt =
    entity.imageThumbnail || entity.bio || entity.displayName;
  return (
    <MetaLayout
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      metaImageUrl={entity.image}
      metaImageAlt={metaImageAlt}
      hideTitleTemplate
    >
      <Helmet>
        {SafeScript({
          type: "application/ld+json",
          children: formatEntityProfilePagePosting(entity, location),
        })}
      </Helmet>
    </MetaLayout>
  );
}

function formatEntityProfilePagePosting(
  entity: EntityHelmetFragment$data,
  location: LocationContextValue,
): JsonValue {
  const sameAs = [
    entity.linkedin && `https://linkedin.com/${entity.linkedin}`,
    entity.github && `https://github.com/${entity.github}`,
    entity.googleScholar &&
      `https://scholar.google.com/citations?&user=${encodeURIComponent(entity.googleScholar)}`,
  ].filter(Boolean);

  const images = [entity.image, entity.imageThumbnail].filter(Boolean);

  const commentList =
    entity.comments?.nodes && entity.comments.nodes.length > 0
      ? entity.comments.nodes.map((comment) => ({
          "@type": "Comment",
          text: comment.content,
          author: {
            "@type": entity.kind === "USER" ? "Person" : "Organization",
            name: entity.displayName,
            url: new URL(`/${entity.username}`, location.origin).toString(),
          },
          datePublished: comment.createdAt,
          interactionStatistic: {
            "@type": "InteractionCounter",
            interactionType: "https://schema.org/LikeAction",
            userInteractionCount: comment.votes,
          },
        }))
      : [];

  return {
    "@context": "https://schema.org",
    "@type": "ProfilePage",
    dateCreated: entity.createdAt,
    mainEntity: {
      "@type": entity.kind === "USER" ? "Person" : "Organization",
      name: entity.displayName,
      alternateName: entity.username,
      identifier: entity.id,
      image: images,
      sameAs,
      ...addIfDefined("thumbnailUrl", entity.imageThumbnail),
      ...addIfDefined("description", entity.bio),
      ...addIfDefined(
        "comment",
        commentList.length > 0 ? commentList : undefined,
      ),
    },
  };
}
