import { PropsWithChildren as Props } from "react";
import { useMemo } from "react";
import {
  Store,
  RecordSource,
  Environment,
  Network,
  Observable,
  RequestParameters,
  Variables,
  GraphQLResponse,
} from "relay-runtime";
import { RecordMap } from "relay-runtime/lib/store/RelayStoreTypes";
import type { FetchFunction } from "relay-runtime";
import { RelayEnvironmentProvider } from "react-relay";
import SuperJSON from "superjson";
import { useAuth } from "../../utils/auth";
import { createClient, type Sink } from "graphql-ws";
import { isEmptyObject } from "../../utils/helpers";
import { logger } from "../logger";

const parseEndpoint = (endpointStr: string) => {
  const endpoint = new URL(endpointStr, document.baseURI);
  const wsEndpoint = new URL(endpoint);
  wsEndpoint.protocol = endpoint.protocol === "https:" ? "wss:" : "ws:";
  return [endpoint, wsEndpoint];
};

const [endpoint, wsEndpoint] = parseEndpoint(
  import.meta.env.VITE_API_URL || "/graphql",
);

interface FetchFunctionAuth {
  getToken: () => string | undefined;
  setTokens: (tokens: { access?: string; refresh?: string }) => void;
  revokeToken: (token_type: "access" | "refresh") => void;
  revokeTokens: () => void;
}

const createFetchFn = (auth: FetchFunctionAuth): FetchFunction => {
  return (operations, variables, _cacheConfig, uploadables) => {
    const doFetch = async () => {
      const headers = new Headers();

      const token = auth.getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      let body: FormData | string = JSON.stringify({
        query: operations.text,
        variables,
      });

      if (uploadables && !isEmptyObject(uploadables)) {
        if (!window.FormData) {
          throw new Error("Uploading files without `FormData` not supported.");
        }

        const formData = new FormData();
        formData.append("operations", body);

        const map: Record<number, string[]> = {};
        Object.keys(uploadables).forEach((key, index) => {
          map[index] = [key];
        });
        formData.append("map", JSON.stringify(map));

        for (const [fileKey, variables] of Object.entries(map)) {
          for (const variable of variables) {
            formData.append(fileKey, uploadables[variable]);
          }
        }

        body = formData;
      } else {
        headers.set("Content-Type", "application/json");
      }

      const response = await fetch(endpoint, {
        method: "POST",
        headers,
        body,
      });
      if (response.headers.has("X-Revoke-Tokens")) {
        auth.revokeTokens();
      }
      if (response.headers.has("X-Refresh-Tokens")) {
        auth.revokeToken("access");
      }
      if (
        response.headers.has("X-Access-Token") ||
        response.headers.has("X-Refresh-Token")
      ) {
        auth.setTokens({
          access: response.headers.get("X-Access-Token") || undefined,
          refresh: response.headers.get("X-Refresh-Token") || undefined,
        });
      }
      const json = await response.json();
      if (typeof json === "object" && Array.isArray(json["errors"])) {
        for (const error of json["errors"]) {
          if (
            typeof error === "object" &&
            typeof error["extensions"] === "object" &&
            typeof error["extensions"]["code"] === "string" &&
            error["extensions"]["code"] === "INVALID_AUTHORIZATION"
          ) {
            auth.revokeTokens();
            break;
          }
        }
      }
      return json;
    };
    return Observable.from(doFetch());
  };
};

const createSubscribe = () => {
  // NOTE: At the moment we only support authentication through cookies
  // as we do not support refresh tokens through websockets.
  const wsClient = createClient({
    url: wsEndpoint.toString(),
  });

  return (operation: RequestParameters, variables: Variables) => {
    return Observable.create<GraphQLResponse>((sink) => {
      if (!operation.text) {
        return sink.error(new Error("Operation text cannot be empty"));
      }
      return wsClient.subscribe(
        {
          operationName: operation.name,
          query: operation.text,
          variables,
        },
        sink as Sink,
      );
    });
  };
};

export default function RelayEnvironment({ children }: Props) {
  const { getToken, setTokens, revokeToken, revokeTokens, userId } = useAuth();
  const network = useMemo(
    () =>
      Network.create(
        createFetchFn({
          getToken: getToken,
          setTokens,
          revokeToken,
          revokeTokens,
        }),
        createSubscribe(),
      ),
    [getToken, setTokens, revokeToken, revokeTokens],
  );
  const store = useMemo(
    () => new Store(new RecordSource(extractDocumentAppData())),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId],
  );
  const environment = useMemo(
    () => new Environment({ store, network, options: { userId: userId } }),
    [store, network, userId],
  );

  return (
    <RelayEnvironmentProvider environment={environment}>
      {children}
    </RelayEnvironmentProvider>
  );
}

function extractDocumentAppData(): RecordMap | undefined {
  const dataScript = document.getElementById("app-data");
  if (!dataScript) {
    return undefined;
  }
  try {
    const data = SuperJSON.parse(dataScript.innerText);
    if (typeof data !== "object" || data == null) {
      return undefined;
    }
    return data as RecordMap;
  } catch (error) {
    logger.error(error, "cannot extract app data from document");
    return undefined;
  }
}
