import { FormattedMessage, useIntl } from "react-intl";
import {
  ConnectionHandler,
  graphql,
  useFragment,
  useMutation,
} from "react-relay";
import { useNavigate } from "react-router-dom";
import { TopicDeleteButtonFragment$key } from "./__generated__/TopicDeleteButtonFragment.graphql";
import { TopicDeleteButtonMutation as TopicDeleteButtonMutationType } from "./__generated__/TopicDeleteButtonMutation.graphql";
import { ConfirmDialog } from "./ConfirmDialog";
import { toast } from "sonner";
import { getForumPath } from "../utils/routing";
import { useContext } from "react";
import { TopicVotableOrderContext } from "../utils/votableOrder";

const TopicDeleteButtonFragment = graphql`
  fragment TopicDeleteButtonFragment on Topic {
    __id
    id
    title
    forum {
      id
      slug
      owner {
        slug
        forumOwnerKind
      }
    }
  }
`;

const TopicDeleteButtonMutation = graphql`
  mutation TopicDeleteButtonMutation($id: ID!, $connections: [ID!]!) {
    deleteTopic(id: $id) @deleteEdge(connections: $connections)
  }
`;

interface Props {
  topic: TopicDeleteButtonFragment$key;
}

export default function TopicDeleteButton({ topic: topicFragment }: Props) {
  const intl = useIntl();

  const navigate = useNavigate();
  const topic = useFragment(TopicDeleteButtonFragment, topicFragment);
  const [commitMutation, isMutationInFlight] =
    useMutation<TopicDeleteButtonMutationType>(TopicDeleteButtonMutation);
  const order = useContext(TopicVotableOrderContext);

  const onDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (isMutationInFlight) {
      return;
    }

    commitMutation({
      variables: {
        id: topic.id,
        connections: [
          ConnectionHandler.getConnectionID(
            topic.forum.id,
            "ForumTopicsFragment_topics",
            { order },
          ),
        ],
      },

      onCompleted: () => {
        toast.success(
          intl.formatMessage({
            defaultMessage: "The topic has been successfully deleted",
          }),
        );

        navigate(getForumPath(topic.forum));
      },

      onError: () => {
        toast.error(
          intl.formatMessage({
            defaultMessage:
              "There was an error while deleting this topic: please try again later...",
          }),
        );
      },
    });
  };

  return (
    <ConfirmDialog
      onConfirm={onDelete}
      kind="danger"
      title={<FormattedMessage defaultMessage="Delete the topic" />}
      buttonMessage={<FormattedMessage defaultMessage="Delete" />}
      message={
        <FormattedMessage
          defaultMessage="By clicking on the button below, this will permanently delete the topic : {topicTitle}."
          values={{ topicTitle: <strong>{topic.title}</strong> }}
        />
      }
    />
  );
}
