/**
 * @generated SignedSource<<2b9788defa6b71100c7e52450b39b559>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ForumOwnerKind = "BLOG" | "COMPETITION" | "EVENT" | "TOP_LEVEL";
import { FragmentRefs } from "relay-runtime";
export type TopicListItemFragment$data = {
  readonly author: {
    readonly username: string;
    readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
  };
  readonly commentCount: number;
  readonly comments: {
    readonly nodes: ReadonlyArray<{
      readonly author: {
        readonly displayName: string;
        readonly username: string;
      };
      readonly createdAt: string;
    }>;
  };
  readonly createdAt: string;
  readonly forum: {
    readonly owner: {
      readonly forumOwnerKind: ForumOwnerKind;
      readonly slug: string;
    };
    readonly slug: string;
  };
  readonly id: string;
  readonly title: string;
  readonly url: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"VoteDisplayFragment">;
  readonly " $fragmentType": "TopicListItemFragment";
};
export type TopicListItemFragment$key = {
  readonly " $data"?: TopicListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicListItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commentCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Forum",
      "kind": "LinkedField",
      "name": "forum",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "owner",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forumOwnerKind",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "args": [
            {
              "kind": "Literal",
              "name": "thumbnail",
              "value": true
            }
          ],
          "kind": "FragmentSpread",
          "name": "EntityProfilePicFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "order",
          "value": "NEWEST"
        }
      ],
      "concreteType": "CommentConnection",
      "kind": "LinkedField",
      "name": "comments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Comment",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "author",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "comments(first:1,order:\"NEWEST\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VoteDisplayFragment"
    }
  ],
  "type": "Topic",
  "abstractKey": null
};
})();

(node as any).hash = "4c9824e64c669a04c450c439ae55a541";

export default node;
