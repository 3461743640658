/**
 * @generated SignedSource<<d45942bdf0ae3a3de0c762ca2054063c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ForumsPageQuery$variables = Record<PropertyKey, never>;
export type ForumsPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ForumsFragment">;
};
export type ForumsPageQuery = {
  response: ForumsPageQuery$data;
  variables: ForumsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ForumsPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ForumsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ForumsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "canCreateForum",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "CREATE_FORUM"
              }
            ],
            "kind": "ScalarField",
            "name": "can",
            "storageKey": "can(action:\"CREATE_FORUM\")"
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ForumConnection",
        "kind": "LinkedField",
        "name": "forums",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ForumEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Forum",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "icon",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "owner",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "forumOwnerKind",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1
                      },
                      {
                        "kind": "Literal",
                        "name": "order",
                        "value": "NEWEST"
                      }
                    ],
                    "concreteType": "TopicConnection",
                    "kind": "LinkedField",
                    "name": "topics",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Topic",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "username",
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "topics(first:1,order:\"NEWEST\")"
                  },
                  {
                    "alias": "viewerCanEdit",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "action",
                        "value": "UPDATE_FORUM"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "viewerCan",
                    "storageKey": "viewerCan(action:\"UPDATE_FORUM\")"
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "forums(first:100)"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": null,
        "handle": "connection",
        "key": "ForumsFragment_forums",
        "kind": "LinkedHandle",
        "name": "forums"
      }
    ]
  },
  "params": {
    "cacheID": "3783a9be63fe220640a0299bc6100f54",
    "id": null,
    "metadata": {},
    "name": "ForumsPageQuery",
    "operationKind": "query",
    "text": "query ForumsPageQuery {\n  ...ForumsFragment\n}\n\nfragment ForumFragment on Forum {\n  slug\n  title\n  shortDescription\n  icon\n  owner {\n    __typename\n    slug\n    forumOwnerKind\n    id\n  }\n  topics(first: 1, order: NEWEST) {\n    nodes {\n      createdAt\n      author {\n        __typename\n        username\n        id\n      }\n      id\n    }\n  }\n  viewerCanEdit: viewerCan(action: UPDATE_FORUM)\n}\n\nfragment ForumsFragment on Query {\n  viewer @ifAllowed {\n    canCreateForum: can(action: CREATE_FORUM)\n    id\n  }\n  forums(first: 100) {\n    edges {\n      node {\n        id\n        ...ForumFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a2d3480c5e26e662f5e35f53e60088d5";

export default node;
