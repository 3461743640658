import React, { useMemo } from "react";
import {
  graphql,
  usePaginationFragment,
  useSubscription,
  ConnectionHandler,
} from "react-relay";
import { FormattedMessage } from "react-intl";
import { TopicCommentsFragment$key } from "./__generated__/TopicCommentsFragment.graphql";
import Comment from "./Comment";
import {
  GraphQLSubscriptionConfig,
  RecordSourceSelectorProxy,
} from "relay-runtime";
import { TopicCommentsNewCommentSubscription as TopicCommentsNewCommentSubscriptionType } from "./__generated__/TopicCommentsNewCommentSubscription.graphql";
import { TopicCommentsDeletedCommentSubscription as TopicCommentsDeletedCommentSubscriptionType } from "./__generated__/TopicCommentsDeletedCommentSubscription.graphql";
import { TopicCommentsUpdatedCommentSubscription as TopicCommentsUpdatedCommentSubscriptionType } from "./__generated__/TopicCommentsUpdatedCommentSubscription.graphql";
import { VotableOrder } from "../utils/votableOrder";
import { useOnChangeEffect } from "../utils/hooks";
import LoadMore from "./LoadMore";
import { Skeleton } from "./Skeleton";

const TopicCommentsFragment = graphql`
  fragment TopicCommentsFragment on Topic
  @refetchable(queryName: "TopicCommentsFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
    order: { type: "VotableOrder", defaultValue: HOT }
  ) {
    comments(first: $count, after: $cursor, order: $order)
      @connection(key: "TopicCommentsFragment_comments") {
      __id
      edges {
        hotness
        node {
          id
          ...CommentFragment @arguments(topLevel: true, order: $order)
        }
      }
    }
  }
`;

const TopicCommentsNewCommentSubscription = graphql`
  subscription TopicCommentsNewCommentSubscription($topicId: ID!) {
    newComments(topicId: $topicId) {
      hotness
      node {
        id
        parent {
          id
        }
        topic {
          id
        }
        ...CommentFragment @arguments(bottomLevel: true)
      }
    }
  }
`;

const TopicCommentsDeletedCommentSubscription = graphql`
  subscription TopicCommentsDeletedCommentSubscription($topicId: ID!) {
    deletedComments(topicId: $topicId) {
      topicId
      parentId
      commentId
    }
  }
`;

const TopicCommentsUpdatedCommentSubscription = graphql`
  subscription TopicCommentsUpdatedCommentSubscription($topicId: ID!) {
    updatedComments(topicId: $topicId) {
      node {
        id
        ...CommentDisplayFragment
      }
    }
  }
`;

const getCommentConnection = (
  store: RecordSourceSelectorProxy,
  order: VotableOrder,
  topicId?: string | null,
  parentId?: string | null,
) => {
  if (parentId) {
    const connectionId = ConnectionHandler.getConnectionID(
      parentId,
      "CommentCommentsFragment_children",
      { order },
    );
    return store.get(connectionId);
  } else if (topicId) {
    const connectionId = ConnectionHandler.getConnectionID(
      topicId,
      "TopicCommentsFragment_comments",
      { order },
    );
    return store.get(connectionId);
  }
  return null;
};

interface Props {
  topic: TopicCommentsFragment$key;
  order: VotableOrder;
  hidePermalink?: boolean;
}

export function TopicComments({
  topic: topicFragment,
  order,
  hidePermalink,
}: Props) {
  const {
    data: topic,
    refetch,
    hasNext,
    loadNext,
    isLoadingNext,
  } = usePaginationFragment(TopicCommentsFragment, topicFragment);
  useOnChangeEffect(order, (order) =>
    refetch({ order }, { fetchPolicy: "store-and-network" }),
  );

  useSubscription(
    useMemo(
      (): GraphQLSubscriptionConfig<TopicCommentsNewCommentSubscriptionType> => ({
        variables: { topicId: topic.id },
        subscription: TopicCommentsNewCommentSubscription,
        updater: (store, response) => {
          const newComment = response?.newComments.node;
          if (!newComment) return;

          const record = store.getRootField("newComments");
          if (!record) return;

          const connection = getCommentConnection(
            store,
            order,
            newComment.topic?.id,
            newComment.parent?.id,
          );
          if (!connection) return;

          const newHotness = response.newComments.hotness;
          let lessHotCursor = undefined;
          for (const edge of connection.getLinkedRecords("edges") || []) {
            const node = edge?.getLinkedRecord("node");
            if (!node) continue;
            // skip if already added
            if (node.getValue("id") === newComment.id) {
              return;
            }
            // find less hot edge to insert before
            if (!lessHotCursor) {
              const cursor = edge.getValue("cursor") as string | undefined;
              if (cursor) {
                const hotness = edge.getValue("hotness") as number | undefined;
                if (hotness != undefined && hotness <= newHotness) {
                  lessHotCursor = cursor;
                }
              }
            }
          }

          const newEdge = ConnectionHandler.buildConnectionEdge(
            store,
            connection,
            record,
          );
          if (!newEdge) return;

          switch (order) {
            case "NEWEST":
              ConnectionHandler.insertEdgeBefore(connection, newEdge);
              break;
            case "OLDEST":
              if (!hasNext) {
                ConnectionHandler.insertEdgeAfter(connection, newEdge);
              }
              break;
            case "HOT":
              if (lessHotCursor) {
                ConnectionHandler.insertEdgeBefore(
                  connection,
                  newEdge,
                  lessHotCursor,
                );
              }
              break;
          }
        },
      }),
      [topic.id, order, hasNext],
    ),
  );

  useSubscription(
    useMemo(
      (): GraphQLSubscriptionConfig<TopicCommentsDeletedCommentSubscriptionType> => ({
        variables: { topicId: topic.id },
        subscription: TopicCommentsDeletedCommentSubscription,
        updater: (store, response) => {
          const id = response?.deletedComments.commentId;
          if (!id) return;

          const connection = getCommentConnection(
            store,
            order,
            response?.deletedComments.topicId,
            response?.deletedComments.parentId,
          );
          if (!connection) return;

          ConnectionHandler.deleteNode(connection, id);
        },
      }),
      [topic.id, order],
    ),
  );

  useSubscription(
    useMemo(
      (): GraphQLSubscriptionConfig<TopicCommentsUpdatedCommentSubscriptionType> => ({
        variables: { topicId: topic.id },
        subscription: TopicCommentsUpdatedCommentSubscription,
      }),
      [topic.id],
    ),
  );

  return (
    <div>
      <div className="pt-4 pb-8">
        <hr />
      </div>
      <div className="flex flex-col space-y-4">
        {topic.comments.edges.map(({ node: comment }, index) => (
          <React.Fragment key={comment.id}>
            <Comment
              key={comment.id}
              comment={comment}
              order={order}
              hidePermalink={hidePermalink}
            />
            {index < topic.comments.edges.length - 1 && (
              <div className="py-2">
                <hr />
              </div>
            )}
          </React.Fragment>
        ))}
        <LoadMore
          hasMore={hasNext}
          isLoading={isLoadingNext}
          loadMore={loadNext}
        >
          <FormattedMessage defaultMessage="Load more replies" />
        </LoadMore>
      </div>
    </div>
  );
}

function TopicCommentsSkeleton() {
  return (
    <div>
      <div className="pt-4 pb-8">
        <Skeleton className="h-px w-full bg-grey/20" />
      </div>
      <div className="flex flex-col space-y-4">
        {[...Array(3)].map((_, index) => (
          <React.Fragment key={index}>
            <div className="space-y-2">
              <Skeleton className="h-6 w-1/4" />
              <Skeleton className="h-4 w-3/4" />
              <Skeleton className="h-4 w-1/2" />
            </div>
            {index < 2 && (
              <div className="py-2">
                <Skeleton className="h-px w-full bg-grey/20" />
              </div>
            )}
          </React.Fragment>
        ))}
        <div className="pt-4">
          <Skeleton className="h-10 w-1/3 rounded-lg" />
        </div>
      </div>
    </div>
  );
}

TopicComments.Skeleton = TopicCommentsSkeleton;
export default TopicComments;
