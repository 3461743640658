import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { preload, usePreloaded } from "../../utils/relay";
import { BlogArticlePageQuery as BlogArticlePageQueryType } from "./__generated__/BlogArticlePageQuery.graphql";
import {
  DEFAULT_VOTABLE_ORDER,
  getVotableOrderParam,
} from "../../utils/votableOrder";

export const ArticlePageQuery = graphql`
  query BlogArticlePageQuery($id: ID!, $order: VotableOrder!) {
    viewer @ifAllowed {
      ...TopicCommentsFormViewerFragment
    }
    node(id: $id) {
      ... on BlogArticle {
        id
        title
        ...BlogArticleFragment @arguments(order: $order)
      }
    }
  }
`;

export const blogArticlePageQueryLoader = (environment: Environment) => {
  return ({ params: { id }, request }: LoaderArgs) => {
    const order = getVotableOrderParam(request) || DEFAULT_VOTABLE_ORDER;
    if (!id) {
      throw new Response("No id", { status: 500 });
    }
    return preload<BlogArticlePageQueryType>(environment, ArticlePageQuery, {
      id,
      order,
    });
  };
};

export const useBlogArticlePageQuery = () =>
  usePreloaded<BlogArticlePageQueryType>();
