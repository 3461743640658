import { Suspense, cloneElement, useEffect, useMemo } from "react";
import { graphql } from "relay-runtime";
import { useFragment, useRefetchableFragment } from "react-relay";
import ActivityCalendar, {
  ThemeInput,
  Activity,
  Skeleton,
} from "react-activity-calendar";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "./Button";
import {
  UserActivityCalendarFragment$key,
  UserActivityCalendarFragment$data,
} from "./__generated__/UserActivityCalendarFragment.graphql";
import { UserActivityFragment$key } from "./__generated__/UserActivityFragment.graphql";
import {
  ACTIVITY_KINDS,
  ActivityFilter,
  formatActivityKind,
  useSetSearchActivityFilters,
} from "../utils/activityTracker";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { EntityActivitiesConnectionKind } from "./__generated__/UserActivityCalendarFragmentQuery.graphql";

const REACT_TOOLTIP_ID = "react-tooltip-id";

const UserActivityFragment = graphql`
  fragment UserActivityFragment on User
  @argumentDefinitions(
    kinds: { type: "[EntityActivitiesConnectionKind!]" }
    date: { type: "String!" }
  ) {
    createdAt
    ...UserActivityCalendarFragment @arguments(kinds: $kinds, date: $date)
  }
`;

const UserActivityCalendarFragment = graphql`
  fragment UserActivityCalendarFragment on User
  @refetchable(queryName: "UserActivityCalendarFragmentQuery")
  @argumentDefinitions(
    kinds: { type: "[EntityActivitiesConnectionKind!]" }
    date: { type: "String!" }
    first: { type: "Int", defaultValue: 366 }
  ) {
    activities(first: $first, after: $date, kinds: $kinds) {
      edges {
        node {
          date
          level
          points
        }
      }
    }
  }
`;

const explicitTheme: ThemeInput = {
  light: ["#eceafc", "#a99cf3", "#6953ea", "#3d24d0", "#25167e"],
  dark: ["#000000", "#101828", "#4328E5", "#182230", "#25167E"],
};

interface UserActivityProps {
  user: UserActivityFragment$key;
  date: string;
  kind?: EntityActivitiesConnectionKind;
}

interface CalendarProps {
  user: UserActivityCalendarFragment$key;
  filters: ActivityFilter;
}

interface ActivityYearFilterProps {
  beginning: string;
  year?: number;
  setYear: (year: number) => void;
}

interface ActivityKindFilterProps {
  kind?: EntityActivitiesConnectionKind | null;
  setKind: (kind?: EntityActivitiesConnectionKind | null) => void;
}

export function UserActivity({
  user: userFragment,
  date: initialDate,
  kind: initialKind,
}: UserActivityProps) {
  const user = useFragment(UserActivityFragment, userFragment);

  const initialYear = useMemo(
    () => new Date(initialDate).getFullYear(),
    [initialDate],
  );
  const filters: ActivityFilter = useMemo(
    () => ({ kind: initialKind, year: initialYear }),
    [initialKind, initialYear],
  );

  const setSearchFilters = useSetSearchActivityFilters();

  const handleFilterChange = ({ year, kind }: Partial<ActivityFilter>) => {
    setSearchFilters({
      year: (year ?? initialYear).toString(),
      kind: kind === null ? undefined : kind ?? initialKind,
    });
  };

  return (
    <div className="flex items-start gap-6 w-full">
      <div className="p-6 border border-grey rounded-md w-full bg-white">
        <Suspense
          fallback={
            <Skeleton theme={explicitTheme} colorScheme="light" loading />
          }
        >
          <Calendar user={user} filters={filters} />
        </Suspense>
        <ActivityKindFilter
          kind={filters.kind}
          setKind={(newKind) => handleFilterChange({ kind: newKind })}
        />
      </div>
      <ActivityYearFilter
        beginning={user.createdAt}
        year={filters.year}
        setYear={(newYear) => handleFilterChange({ year: newYear })}
      />
    </div>
  );
}

function Calendar({ user: userFragment, filters }: CalendarProps) {
  const intl = useIntl();
  const [user, refetch] = useRefetchableFragment(
    UserActivityCalendarFragment,
    userFragment,
  );
  const year = filters.year || new Date().getFullYear();

  useEffect(() => {
    refetch({
      date: `${year}-01-01`,
      kinds: filters.kind ? [filters.kind] : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const data = useMemo(() => {
    return generateActivities(year, user.activities.edges);
  }, [year, user.activities.edges]);

  if (!data.length) {
    return (
      <div className="container mx-auto h-full flex justify-center items-center px-4 py-4">
        <div className="w-full text-center">
          <p className="text-gray-500 italic">
            <FormattedMessage defaultMessage="No activity yet!" />
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <ActivityCalendar
        data={data}
        colorScheme="light"
        theme={explicitTheme}
        weekStart={1}
        maxLevel={4}
        showWeekdayLabels
        hideTotalCount
        renderBlock={(block, activity) =>
          cloneElement(block, {
            "data-tooltip-id": REACT_TOOLTIP_ID,
            "data-tooltip-html": intl.formatMessage(
              {
                defaultMessage:
                  "{count, plural, one {# point} other {# points}} on {date}",
              },
              {
                count: activity.count,
                date: intl.formatDate(activity.date, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }),
              },
            ),
          })
        }
      />
      <ReactTooltip id={REACT_TOOLTIP_ID} />
    </>
  );
}

function ActivityYearFilter({
  beginning,
  year: activeYear,
  setYear,
}: ActivityYearFilterProps) {
  return (
    <div className="flex flex-col gap-3">
      {generateYears(
        new Date(beginning).getFullYear(),
        new Date().getFullYear(),
      ).map((year) => (
        <Button
          key={year}
          kind={activeYear == year ? "primary" : "secondary"}
          onClick={() => {
            setYear(year);
          }}
        >
          {year}
        </Button>
      ))}
    </div>
  );
}

function ActivityKindFilter({
  kind: activeKind,
  setKind,
}: ActivityKindFilterProps) {
  const intl = useIntl();
  return (
    <div className="flex gap-3 mt-8">
      {ACTIVITY_KINDS.map((kind) => (
        <Button
          key={kind}
          kind={activeKind == kind ? "primary" : "secondary"}
          size="sm"
          onClick={() => setKind(kind)}
        >
          {formatActivityKind(intl, kind)}
        </Button>
      ))}
      <Button
        kind={activeKind == undefined ? "primary" : "secondary"}
        size="sm"
        onClick={() => setKind(null)}
      >
        {formatActivityKind(intl)}
      </Button>
    </div>
  );
}

function generateYears(start: number, end: number): number[] {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i).reverse();
}

const generateActivities = (
  startYear: number,
  activityEdges: UserActivityCalendarFragment$data["activities"]["edges"],
): Activity[] => {
  const activityMap = activityEdges.reduce<Record<string, Activity>>(
    (acc, { node }) => {
      if (!node.date) return acc;
      acc[node.date] = {
        level: node.level,
        count: node.points,
        date: node.date,
      };
      return acc;
    },
    {},
  );

  return Array.from(daysInYear(startYear)).map((date) => {
    const isoDate = date.toISOString().split("T")[0];
    console.log(isoDate);
    return activityMap[isoDate] ?? { level: 0, count: 0, date: isoDate };
  });
};

const daysInYear = function* (year: number) {
  const startDate = new Date(Date.UTC(year, 0, 1));
  const endDate = new Date(Date.UTC(year + 1, 0, 1));

  const current = startDate;
  while (current < endDate) {
    yield new Date(current);
    current.setUTCDate(current.getUTCDate() + 1);
  }
};
