/**
 * @generated SignedSource<<9f961833aaeacbcc6f31e6ac581b1d4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionEntitySubmissionStatusSubscription$variables = {
  competitionId: string;
  entityId: string;
};
export type CompetitionEntitySubmissionStatusSubscription$data = {
  readonly projectVersionStatusUpdate: {
    readonly evaluation: {
      readonly max: boolean;
    } | null | undefined;
    readonly latest: boolean;
    readonly project: {
      readonly competition: {
        readonly " $fragmentSpreads": FragmentRefs<"CompetitionEntitySubmissionStatusCompetitionFragment">;
      };
    };
  };
};
export type CompetitionEntitySubmissionStatusSubscription = {
  response: CompetitionEntitySubmissionStatusSubscription$data;
  variables: CompetitionEntitySubmissionStatusSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "competitionId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entityId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "competitionId",
    "variableName": "competitionId"
  },
  {
    "kind": "Variable",
    "name": "entityId",
    "variableName": "entityId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latest",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "max",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "version",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectVersionEvaluation",
    "kind": "LinkedField",
    "name": "evaluation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "score",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "finalizedAt",
        "storageKey": null
      },
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "project",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasLeaderboard",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "noCode",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionEntitySubmissionStatusSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectVersion",
        "kind": "LinkedField",
        "name": "projectVersionStatusUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectVersionEvaluation",
            "kind": "LinkedField",
            "name": "evaluation",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Competition",
                "kind": "LinkedField",
                "name": "competition",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CompetitionEntitySubmissionStatusCompetitionFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetitionEntitySubmissionStatusSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectVersion",
        "kind": "LinkedField",
        "name": "projectVersionStatusUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectVersionEvaluation",
            "kind": "LinkedField",
            "name": "evaluation",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Competition",
                "kind": "LinkedField",
                "name": "competition",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Submission",
                    "kind": "LinkedField",
                    "name": "submission",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectVersion",
                        "kind": "LinkedField",
                        "name": "latest",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectVersionEvaluation",
                        "kind": "LinkedField",
                        "name": "maxEvaluation",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectVersion",
                            "kind": "LinkedField",
                            "name": "projectVersion",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be820c10f83b4be5f7d071f94668d747",
    "id": null,
    "metadata": {},
    "name": "CompetitionEntitySubmissionStatusSubscription",
    "operationKind": "subscription",
    "text": "subscription CompetitionEntitySubmissionStatusSubscription(\n  $competitionId: ID!\n  $entityId: ID!\n) {\n  projectVersionStatusUpdate(competitionId: $competitionId, entityId: $entityId) {\n    latest\n    evaluation {\n      max\n      id\n    }\n    project {\n      __typename\n      competition {\n        ...CompetitionEntitySubmissionStatusCompetitionFragment\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment CompetitionEntitySubmissionStatusCompetitionFragment on Competition {\n  id\n  slug\n  submission @ifAllowed {\n    id\n    latest {\n      id\n      version\n      ...SubmissionStatusBadgeFragment\n    }\n    maxEvaluation {\n      projectVersion {\n        id\n        version\n        ...SubmissionStatusBadgeFragment\n      }\n      id\n    }\n  }\n}\n\nfragment SubmissionStatusBadgeFragment on ProjectVersion {\n  id\n  status\n  version\n  evaluation {\n    score\n    error\n    finalizedAt\n    max\n    id\n  }\n  project {\n    __typename\n    competition {\n      hasLeaderboard\n      noCode\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b57aa4515180cbb38fd30dd085de8fa7";

export default node;
