import { useEffect } from "react";
import Outlet from "../common/Outlet";
import Card from "../components/Card";
import Logo from "../components/Logo";
import { useAuthScaffoldQuery } from "./loaders/AuthScaffold";
import { useOnAuthCallback } from "../utils/auth";

export default function AuthScaffold() {
  const {
    query: { viewer },
  } = useAuthScaffoldQuery();
  const onAuthenticated = useOnAuthCallback();
  useEffect(() => {
    if (viewer) {
      onAuthenticated();
    }
  }, [viewer, onAuthenticated]);
  return (
    <div className="container mx-auto min-h-full flex justify-center items-center px-4 py-4">
      <div className="w-full max-w-lg flex flex-col">
        <div className="w-48 py-4">
          <Logo />
        </div>
        <Card className="p-8">
          <Outlet />
        </Card>
      </div>
    </div>
  );
}
