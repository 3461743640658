import {
  useState,
  useEffect,
  useMemo,
  useLayoutEffect,
  useRef,
  useCallback,
} from "react";
import { encodeArrayBufferToBase64 } from "./base64ArrayBuffer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocation } from "./location";
import { debounce } from "lodash";
import { DEFAULT_VOTABLE_ORDER, VotableOrder } from "./votableOrder";

export function useUpload<T>(
  defaultValue: T | undefined,
  value: FileList | null,
  reset: () => void,
) {
  const [shouldDeleteImage, setShouldDeleteImage] = useState(false);
  const hasDefaultValue = !!defaultValue;
  const hasValue = value && value.length > 0;
  useEffect(() => {
    if (hasValue) {
      setShouldDeleteImage(false);
    }
  }, [hasValue]);

  return {
    deleteImage: () => {
      reset();
      if (hasDefaultValue) {
        setShouldDeleteImage(true);
      }
    },
    reset: () => {
      reset();
      setShouldDeleteImage(false);
    },
    canDelete: hasDefaultValue && !shouldDeleteImage,
    isDirty: hasValue || shouldDeleteImage,
    variable: shouldDeleteImage || hasValue ? null : undefined,
    value: hasValue ? value[0] : undefined,
  };
}

export function useImagePreview(value: File | undefined) {
  return useMemo(
    () => (value ? URL.createObjectURL(value) : undefined),
    [value],
  );
}

export function useClickOutside(
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !event.defaultPrevented
      ) {
        callback();
      }
    };
    document.addEventListener("mousedown", listener);
    return () => document.removeEventListener("mousedown", listener);
  }, [ref, callback]);
}

export type WindowDimensions = {
  width: number;
  height: number;
};

function getWindowDimensions(): WindowDimensions {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function useFetchText(url: string) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<string | null>(null);
  const [isText, setIsText] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [aborted, setAborted] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    setAborted(false);
    const controller = new AbortController();
    fetch(url, { signal: controller.signal })
      .then(async (response) => {
        const decoder = new TextDecoder("utf-8", { fatal: true });
        const buffer = await response.arrayBuffer();
        try {
          const text = decoder.decode(buffer);
          setData(text);
        } catch {
          setData(encodeArrayBufferToBase64(buffer));
          setIsText(false);
        }
      })
      .catch((error) => {
        if (!controller.signal.aborted) {
          setError(error);
        }
      })
      .finally(() => {
        if (!controller.signal.aborted) {
          setIsLoading(false);
        }
      });
    return () => {
      if (!controller.signal.aborted) {
        setAborted(true);
        controller.abort();
      }
    };
  }, [url]);
  return { isLoading, data, isText, error, aborted };
}

export function useMediaQuery(query: string) {
  const [value, setValue] = useState<boolean>(false);

  useEffect(() => {
    function onChange(event: MediaQueryListEvent) {
      setValue(event.matches);
    }

    const result = window.matchMedia(query);
    result.addEventListener("change", onChange);
    setValue(result.matches);

    return () => result.removeEventListener("change", onChange);
  }, [query]);

  return value;
}

export const useIsomorphicLayoutEffect = import.meta.env.SSR
  ? useEffect
  : useLayoutEffect;

export const useOnChangeEffect = <T>(value: T, effect: (value: T) => void) => {
  const ref = useRef(value);
  useLayoutEffect(() => {
    if (ref.current != value) {
      effect(value);
    }
    ref.current = value;
  }, [value, effect]);
};

export function useExpectedLocationPath(path: string | null) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (path !== null && location.pathname !== path) {
      navigate(path, { replace: true });
    }
  }, [location, path, navigate]);
}

interface DebounceOptions {
  leading?: boolean;
  maxWait?: number;
  trailing?: boolean;
}

export function useDebounced<T>(
  value: T,
  wait: number = 0,
  options: DebounceOptions = {},
) {
  const [state, setState] = useState<T>(value);
  const setDebounced = useRef(debounce(setState, wait, options));
  useEffect(() => {
    if (setDebounced.current) {
      setDebounced.current(value);
    }
  }, [value]);
  return state;
}

export function useVotableOrder(
  initial: VotableOrder = DEFAULT_VOTABLE_ORDER,
  queryParamName = "order",
): [VotableOrder, (order: VotableOrder) => void] {
  const [_, setSearchParams] = useSearchParams();
  const [state, setState] = useState(initial);
  const updateState = useCallback(
    (newOrder: VotableOrder) => {
      setState(newOrder);
      setSearchParams(
        { [queryParamName]: newOrder },
        { preventScrollReset: true },
      );
    },
    [setState, setSearchParams, queryParamName],
  );
  return [state, updateState];
}

export function useCache<K extends string | number | symbol, V>() {
  const [cache, setCache] = useState<Record<K, V>>({} as Record<K, V>);
  return {
    get: (key: K) => cache[key],
    set: (key: K, value: V) => setCache((prev) => ({ ...prev, [key]: value })),
    clear: () => setCache({} as Record<K, V>),
  };
}
