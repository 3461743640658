import { useState } from "react";
import { graphql, useMutation } from "react-relay";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { useLocation } from "../utils/location";
import { ResetPasswordPageMutation as ResetPasswordPageMutationType } from "./__generated__/ResetPasswordPageMutation.graphql";
import Button from "../components/Button";
import FormGroup from "../components/FormGroup";
import TextInput from "../components/TextInput";
import Helmet from "../components/Helmet";

const ResetPasswordPageMutation = graphql`
  mutation ResetPasswordPageMutation($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

type FormData = {
  password: string;
};

export default function ResetPasswordPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const { secret } = useParams();
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [commitMutation, isMutationInFlight] =
    useMutation<ResetPasswordPageMutationType>(ResetPasswordPageMutation);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const onSubmit = handleSubmit((data) => {
    setFormError(undefined);
    commitMutation({
      variables: {
        input: {
          password: data.password,
          secret: secret || "",
        },
      },
      onError() {
        setFormError(
          intl.formatMessage({
            defaultMessage:
              "Could not reset password. The reset link may have expired.",
          }),
        );
      },
      onCompleted() {
        location.replace("/");
      },
    });
  });
  const errorMessages = {
    required: intl.formatMessage({ defaultMessage: "Password is required" }),
    minLength: intl.formatMessage({
      defaultMessage: "Password must be at least 6 characters",
    }),
  };
  return (
    <form onSubmit={onSubmit}>
      <Helmet meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <div className="flex flex-col space-y-3">
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "New Password" })}
          error={
            typeof errors.password?.type === "string" &&
            errorMessages[errors.password.type as keyof typeof errorMessages]
          }
        >
          <TextInput
            type="password"
            aria-invalid={errors.password ? "true" : "false"}
            {...register("password", {
              required: true,
              minLength: 6,
            })}
          />
        </FormGroup>
        {formError && <p className="pt-1 text-sm text-red-500">{formError}</p>}
        <div className="py-3 flex justify-between items-center">
          <a
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="text-gray-600 hover:text-gray-700 hover:cursor-pointer"
          >
            <FormattedMessage defaultMessage="Go back" />
          </a>
          <Button type="submit" disabled={isMutationInFlight}>
            <FormattedMessage defaultMessage="Reset Password" />
          </Button>
        </div>
      </div>
    </form>
  );
}
