import { graphql, useFragment } from "react-relay";
import EntityAvatar from "./EntityAvatar";
import { BlogArticleAuthorsFragment$key } from "./__generated__/BlogArticleAuthorsFragment.graphql";

const Fragment = graphql`
  fragment BlogArticleAuthorsFragment on BlogArticle {
    authors {
      edges {
        node {
          ... on User {
            jobTitle
          }
          ...EntityAvatarFragment
        }
      }
    }
  }
`;

export interface BlogArticleAuthorsProps {
  article: BlogArticleAuthorsFragment$key;
}

export default function BlogArticleAuthors(props: BlogArticleAuthorsProps) {
  const { authors } = useFragment(Fragment, props.article);
  return (
    <>
      {authors.edges.map((author, index) => (
        <EntityAvatar
          key={index}
          entity={author.node}
          label={author.node.jobTitle}
        />
      ))}
    </>
  );
}
