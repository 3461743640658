import {
  DragEventHandler,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  Suspense,
  useState,
} from "react";
import { IoMdTrash } from "react-icons/io";
import { MdInsertDriveFile, MdUploadFile } from "react-icons/md";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { graphql, useMutation } from "react-relay";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { logger } from "../common/logger";
import ErrorPage from "./ErrorPage";
import { useCompetitionNoCodeSubmissionPageQuery } from "./loaders/CompetitionNoCodeSubmissionPage";
import Button from "../components/Button";
import CompetitionAcceptRulesButton from "../components/CompetitionAcceptRulesButton";
import CompetitionEntitySubmissionStatus from "../components/CompetitionEntitySubmissionStatus";
import EditMarkdownSection from "../components/EditMarkdownSection";
import FormGroup from "../components/FormGroup";
import Loading from "../components/Loading";
import Markdown from "../components/Markdown";
import UserEntitiesAutocomplete from "../components/UserEntitiesAutocomplete";
import {
  RelayNetworkError,
  collectRelayPayloadErrorCodes,
  isRelayNetworkError,
} from "../utils/relay";
import { cn } from "../utils/tailwind";
import { CompetitionNoCodeSubmissionPageSubmitMutation } from "./__generated__/CompetitionNoCodeSubmissionPageSubmitMutation.graphql";
import { CompetitionNoCodeSubmissionPageUpdatePreambleMutation } from "./__generated__/CompetitionNoCodeSubmissionPageUpdatePreambleMutation.graphql";

const SubmitMutation = graphql`
  mutation CompetitionNoCodeSubmissionPageSubmitMutation(
    $competition: ID!
    $files: [Upload!]!
    $asEntity: UsernameOrID!
  ) {
    uploadNoCodeSubmissionVersion(
      competitionId: $competition
      input: { files: $files }
      asEntity: $asEntity
    ) {
      node {
        id
        files {
          id
          kind
          uploadUrl
        }
      }
    }
  }
`;

const UpdatePreambleMutation = graphql`
  mutation CompetitionNoCodeSubmissionPageUpdatePreambleMutation(
    $competition: ID!
    $submissionPreamble: String
  ) {
    updateCompetition(
      id: $competition
      input: { submissionPreamble: $submissionPreamble }
    ) {
      node {
        id
        submissionPreamble
      }
    }
  }
`;

export default function CompetitionNoCodeSubmission() {
  const intl = useIntl();
  const {
    query: { competitionBySlug: competition, viewer },
  } = useCompetitionNoCodeSubmissionPageQuery();

  if (!competition) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Competition not found",
        })}
      />
    );
  }

  if (!viewer) {
    return (
      <ErrorPage status={401}>
        <FormattedMessage defaultMessage="Only authenticated users may submit documents." />
        <br />
        <Link
          className="text-blue-500"
          to={`/login?login_redirect=${encodeURIComponent(location.href)}`}
        >
          <FormattedMessage defaultMessage="Try logging in to view this page." />
        </Link>
      </ErrorPage>
    );
  }

  return (
    <div className="flex flex-col gap-4 pb-4">
      <CompetitionEntitySubmissionStatus
        competition={competition}
        entity={viewer}
      />

      <PreambleSection />

      {competition.latestRule.entityAgreement ? (
        <SubmitSection />
      ) : (
        <AcceptRulesSection />
      )}
    </div>
  );
}

function PreambleSection() {
  const intl = useIntl();
  const {
    query: { competitionBySlug: competition },
  } = useCompetitionNoCodeSubmissionPageQuery();

  const [commitUpdatePreambleMutation, isUpdatePreambleMutationInFlight] =
    useMutation<CompetitionNoCodeSubmissionPageUpdatePreambleMutation>(
      UpdatePreambleMutation,
    );

  if (!competition) return null;

  return (
    <EditMarkdownSection
      members={{
        kind: "competition",
        members: competition,
      }}
      canEdit={competition.viewerCanUpdate}
      canUploadFiles
      disabled={isUpdatePreambleMutationInFlight}
      defaultValue={competition.submissionPreamble ?? ""}
      onSubmit={(value, setError, onCompleted) => {
        commitUpdatePreambleMutation({
          variables: {
            competition: competition.id,
            submissionPreamble: value || null,
          },
          onCompleted,
          onError: (error) => {
            logger.error(error);
            setError(
              intl.formatMessage({
                defaultMessage:
                  "An error occurred while saving the description. Please, try again later.",
              }),
            );
          },
        });
      }}
    />
  );
}

function AcceptRulesSection() {
  const {
    query: { competitionBySlug: competition },
  } = useCompetitionNoCodeSubmissionPageQuery();

  if (!competition) return null;

  return (
    <>
      <div className="flex flex-row space-x-5">
        <div className="flex-grow">
          <h1 className="text-2xl font-bold w-full font-poppins">
            <FormattedMessage defaultMessage="Rules" />
          </h1>
          <p className="py-2">
            <FormattedMessage defaultMessage="By default all competitions must comply to the rules defined in" />{" "}
            <a
              href="https://aqora.io/terms"
              target="_blank"
              rel="noreferrer"
              className="text-blue-400"
            >
              <FormattedMessage defaultMessage="aqora's Terms of Use" />
            </a>
          </p>
        </div>
        <div className="self-center">
          {competition.viewerCanAcceptRules && (
            <Suspense fallback={<Loading className="w-10" />}>
              <CompetitionAcceptRulesButton competition={competition} />
            </Suspense>
          )}
        </div>
      </div>

      <Markdown>{competition.latestRule.text}</Markdown>
    </>
  );
}

function SubmitSection() {
  const {
    query: { competitionBySlug: competition, viewer },
  } = useCompetitionNoCodeSubmissionPageQuery();
  const [uploadFiles, setUploadFiles] = useState<Array<File>>([]);
  const [uploadAsEntity, setUploadAsEntity] = useState<string>(viewer?.id);
  const [commitSubmitMutation, isSubmitMutationInFlight] =
    useMutation<CompetitionNoCodeSubmissionPageSubmitMutation>(SubmitMutation);

  const showUserSelect =
    viewer.entitiesHead.edges.length > 1 ||
    viewer.entitiesHead.edges[0].node.id !== viewer.id;

  const onFileRemove = (index: number) => (_evt: MouseEvent) => {
    setUploadFiles((files) => {
      const newFiles = files.slice();
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const onSubmit = (_evt: MouseEvent) => {
    if (!competition || !viewer) return;

    commitSubmitMutation({
      variables: {
        competition: competition.id,
        files: Array(uploadFiles.length).fill(null),
        asEntity: uploadAsEntity,
      },
      uploadables: Object.fromEntries(
        uploadFiles.map((file, index) => [`variables.files.${index}`, file]),
      ),
      onCompleted: () => {
        const fileCount = uploadFiles.length;
        setUploadFiles([]);
        toast.info(
          <FormattedMessage
            defaultMessage="{files, plural, one {# file has} other {# files have}} been successfully submitted!"
            values={{ files: fileCount }}
          />,
        );
      },
      onError: (error) => {
        let message: React.ReactNode | undefined;

        if (isRelayNetworkError(error)) {
          message = formatErrorFromBackend(error);
        }

        if (typeof message === "undefined") {
          logger.error(error);
          message = (
            <FormattedMessage defaultMessage="There was an error while submitting your files, please retry some time later..." />
          );
        }

        toast.error(message);
      },
    });
  };

  return (
    <>
      <FileDropZone
        onFileSelected={(newFiles) =>
          setUploadFiles((files) => files.concat(newFiles))
        }
      />

      {showUserSelect && uploadFiles.length > 0 && (
        <FormGroup label={<FormattedMessage defaultMessage="Submit as" />}>
          <UserEntitiesAutocomplete
            query={viewer}
            onChange={(evt) => {
              setUploadAsEntity(evt.target.value);
            }}
          />
        </FormGroup>
      )}

      <ul className="flex flex-col gap-4">
        {uploadFiles.map((file, index) => (
          <li
            key={index}
            className="flex flex-row gap-4 p-4 items-center bg-gray-50 border-gray-200 border rounded-xl"
          >
            <MdInsertDriveFile className="text-indigo text-4xl" />

            <div className="flex-grow">
              <p className="pb-1 text-gray-700">{file.name}</p>
              <p className="text-gray-600">
                <FormattedNumber
                  value={file.size}
                  notation="compact"
                  style="unit"
                  unit="byte"
                  unitDisplay="narrow"
                />
              </p>
            </div>

            <button
              className="text-xl text-gray-600"
              onClick={onFileRemove(index)}
              title="Remove file"
            >
              <IoMdTrash />
            </button>
          </li>
        ))}
      </ul>

      {uploadFiles.length > 0 && (
        <Button disabled={isSubmitMutationInFlight} onClick={onSubmit}>
          {isSubmitMutationInFlight ? (
            <FormattedMessage
              defaultMessage="Uploading {files,plural,one {# file} other {# files}}..."
              values={{ files: uploadFiles.length }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="Submit {files,plural,one {# file} other {# files}}"
              values={{ files: uploadFiles.length }}
            />
          )}
        </Button>
      )}
    </>
  );
}

function formatErrorFromBackend(error: RelayNetworkError): ReactNode {
  const errors = collectRelayPayloadErrorCodes<
    "COMPETITION_RULE_NOT_ACCEPTED" | "UPLOAD_TOO_BIG" | "DUPLICATED_FILENAME"
  >(error);

  if (errors.COMPETITION_RULE_NOT_ACCEPTED) {
    return (
      <FormattedMessage defaultMessage="Please accept competition rules first." />
    );
  } else if (errors.UPLOAD_TOO_BIG) {
    const maxSize = errors.UPLOAD_TOO_BIG.extensions?.["max_size"];
    if (typeof maxSize === "number") {
      return (
        <FormattedMessage
          defaultMessage="Upload total size is limited to {maxSize}"
          values={{
            maxSize: (
              <FormattedNumber
                value={maxSize}
                notation="compact"
                style="unit"
                unit="byte"
                unitDisplay="narrow"
              />
            ),
          }}
        />
      );
    } else {
      return <FormattedMessage defaultMessage="Upload total size is too big" />;
    }
  } else if (errors.DUPLICATED_FILENAME) {
    const filename = errors.DUPLICATED_FILENAME.extensions?.["filename"];
    return (
      <FormattedMessage
        defaultMessage={`Cannot upload file with the same name: "{filename}"`}
        values={{ filename: filename ? String(filename) : null }}
      />
    );
  }
}

interface FileDropZoneProps {
  onFileSelected: (files: File[]) => void;
}

function FileDropZone(props: FileDropZoneProps) {
  const [isDragging, setDragging] = useState(false);

  const onDragOver: DragEventHandler = (evt) => {
    evt.preventDefault();
    setDragging(true);
  };

  const onDragLeave: DragEventHandler = () => {
    setDragging(false);
  };

  const onDrop: DragEventHandler = (evt) => {
    evt.preventDefault();
    setDragging(false);

    if (evt.dataTransfer.files.length) {
      props.onFileSelected(Array.prototype.slice.call(evt.dataTransfer.files));
    }
  };

  const onBrowse: MouseEventHandler = async () => {
    const files = await openFileBrowser(true);
    if (files?.length) {
      props.onFileSelected(Array.prototype.slice.call(files));
    }
  };

  return (
    <div
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      className={cn(
        "flex flex-col items-center justify-center p-12 border rounded-lg gap-4 transition-colors",
        isDragging ? "border-indigo bg-indigo/10" : "border-gray-200",
      )}
    >
      <MdUploadFile
        className={cn(
          "w-12 h-12",
          isDragging ? "text-indigo" : "text-gray-400",
        )}
      />
      <p className="text-center text-gray-600">
        <FormattedMessage
          defaultMessage="<btn>Click to upload</btn> or drag and drop"
          values={{
            btn: (children) => (
              <button className="text-indigo" onClick={onBrowse}>
                {children}
              </button>
            ),
          }}
        />
      </p>
      <p className="text-sm text-gray-600">
        <FormattedMessage defaultMessage="Supports all file types" />
      </p>
    </div>
  );
}

function openFileBrowser(
  multiple = false,
  accept = "",
): Promise<FileList | null> {
  const element = document.createElement("input");
  element.type = "file";
  element.multiple = multiple;
  element.accept = accept;
  return new Promise((resolve) => {
    element.addEventListener("change", () => {
      resolve(element.files);
    });
    element.addEventListener("cancel", () => {
      resolve(null);
    });
    element.click();
  });
}
