import { Link } from "react-router-dom";
import NavItems from "./NavItems";
import Button from "./Button";
import Logo from "./Logo";
import { FormattedMessage, useIntl } from "react-intl";
import { FaGithub, FaLinkedin, FaReddit } from "react-icons/fa6";

export default function Footer() {
  const intl = useIntl();
  return (
    <footer className="sm:px-28 px-4 sm:pt-16 pt-10 sm:pb-12 pb-4 bg-gray-50 flex-col justify-center items-center sm:gap-16 gap-8 inline-flex overflow-hidden w-full">
      <div className="flex flex-col sm:items-center items-start gap-6 w-full -ml-6">
        <Link
          to="/"
          aria-label={intl.formatMessage({ defaultMessage: "Go to homepage" })}
        >
          <Button kind="text" className="flex items-center mr-2">
            <div
              className="w-36 h-8"
              aria-label={intl.formatMessage({ defaultMessage: "Aqora logo" })}
            >
              <Logo />
            </div>
          </Button>
        </Link>
        <nav
          className="flex flex-row sm:justify-center justify-start w-full flex-wrap"
          role="navigation"
        >
          <NavItems showEvents={true} variant="link" />
        </nav>
      </div>
      <div className="sm:py-4 py-8 border-t border-t-gray-200 w-full">
        <section
          className="flex sm:flex-row flex-col-reverse sm:items-center items-start justify-between gap-4 text-gray-400 "
          aria-label={intl.formatMessage({
            defaultMessage: "Footer links and social media",
          })}
        >
          <div>
            <p className="text-gray-500 text-base font-normal leading-normal">
              <FormattedMessage
                defaultMessage="© {year} Aqora Quantum S.A.S"
                values={{ year: new Date().getUTCFullYear() }}
              />
            </p>
          </div>
          <div className="flex sm:flex-row flex-col gap-4">
            <nav
              className="flex flex-row gap-4 text-gray-500"
              role="navigation"
            >
              <Link
                to="https://aqora.io/terms"
                target="_blank"
                aria-label={intl.formatMessage({
                  defaultMessage: "Terms of Service page",
                })}
              >
                <FormattedMessage defaultMessage="Terms" />
              </Link>
              <Link
                to="https://aqora.io/privacy-policy"
                target="_blank"
                aria-label={intl.formatMessage({
                  defaultMessage: "Privacy Policy page",
                })}
              >
                <FormattedMessage defaultMessage="Privacy" />
              </Link>
              <Link
                to="https://aqora.io/legal-notice"
                target="_blank"
                aria-label={intl.formatMessage({
                  defaultMessage: "Legal Notice page",
                })}
              >
                <FormattedMessage defaultMessage="Legal Notice" />
              </Link>
            </nav>
            <div className="flex flex-row gap-4 text-gray-400">
              <Link
                to="https://www.linkedin.com/company/aqora-quantum/"
                target="_blank"
                aria-label={intl.formatMessage({
                  defaultMessage: "Visit Aqora Quantum's LinkedIn page",
                })}
              >
                <FaLinkedin size={ICON_SIZE} />
              </Link>
              <Link
                to="https://github.com/aqora-io/"
                target="_blank"
                aria-label={intl.formatMessage({
                  defaultMessage: "Visit Aqora Quantum's Github page",
                })}
              >
                <FaGithub size={ICON_SIZE} />
              </Link>
              <Link
                to="https://www.reddit.com/user/aqora-io/"
                target="_blank"
                aria-label={intl.formatMessage({
                  defaultMessage: "Visit Aqora Quantum's Reddit page",
                })}
              >
                <FaReddit size={ICON_SIZE} />
              </Link>
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
}

const ICON_SIZE = 24;
