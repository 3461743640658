import jsesc from "jsesc";

export type SafeScriptType = "application/json" | "application/ld+json";

export type JsonValue =
  | undefined
  | null
  | boolean
  | number
  | string
  | JsonArray
  | JsonObject;
export interface JsonArray extends Array<JsonValue> {}
export interface JsonObject extends Record<string, JsonValue> {}

export interface SafeScriptProps {
  type: SafeScriptType;
  children: JsonValue;
}

export function SafeScript({ children, type }: SafeScriptProps) {
  return (
    <script type={type}>
      {jsesc(children, { json: true, isScriptContext: true })}
    </script>
  );
}
