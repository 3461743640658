/**
 * @generated SignedSource<<0fad04e79bd2781c718bb7925f8a4ccc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ForumOwnerKind = "BLOG" | "COMPETITION" | "EVENT" | "TOP_LEVEL";
import { FragmentRefs } from "relay-runtime";
export type TopicLinkFragment$data = {
  readonly forum: {
    readonly owner: {
      readonly forumOwnerKind: ForumOwnerKind;
      readonly slug: string;
    };
    readonly slug: string;
  };
  readonly id: string;
  readonly title: string;
  readonly " $fragmentType": "TopicLinkFragment";
};
export type TopicLinkFragment$key = {
  readonly " $data"?: TopicLinkFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicLinkFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicLinkFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Forum",
      "kind": "LinkedField",
      "name": "forum",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "owner",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forumOwnerKind",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Topic",
  "abstractKey": null
};
})();

(node as any).hash = "82441c3df6e9307c49647310e5bceb91";

export default node;
