import { graphql, useFragment } from "react-relay";
import { Link } from "react-router-dom";
import EntityAvatar from "./EntityAvatar";
import LazyImage from "./LazyImage";
import { BlogArticleCardFragment$key } from "./__generated__/BlogArticleCardFragment.graphql";
import { cn } from "../utils/tailwind";
import { getBlogArticlePath } from "../utils/routing";
import { FormattedDate } from "react-intl";

const BlogArticleCardFragment = graphql`
  fragment BlogArticleCardFragment on BlogArticle {
    id
    title
    shortDescription
    image
    blurHash
    createdAt
    firstAuthor: authors(first: 1) {
      nodes {
        ...EntityAvatarFragment
      }
    }
    viewerCanEdit: viewerCan(action: UPDATE_BLOG_ARTICLE)
  }
`;

export interface BlogArticleCardProps {
  article: BlogArticleCardFragment$key;
  className?: string | undefined;
}

export function BlogArticleCard(props: BlogArticleCardProps) {
  const article = useFragment(BlogArticleCardFragment, props.article);

  return (
    <Link to={getBlogArticlePath(article)}>
      <div
        className={cn(
          props.className,
          "rounded-xl flex flex-col items-start gap-3",
        )}
      >
        <LazyImage
          blurHash={article.blurHash}
          ratio={16 / 9}
          className="rounded-xl inline-flex overflow-hidden relative border border-gray-300/75"
          image={{
            src: article.image,
            className: "h-full w-full object-cover object-center rounded-lg",
            alt: "Thumbnail",
          }}
        />

        <div className="flex gap-3 flex-col">
          <div className="flex flex-col gap-3  justify-between">
            <p className="text-lg font-semibold text-gray-900 leading-7 line-clamp-2">
              {article.title}
            </p>

            <p className="text-base font-normal text-gray-600 leading-normal line-clamp-2">
              {article?.shortDescription}
            </p>

            <EntityAvatar
              entity={article.firstAuthor.nodes[0]}
              label={
                <FormattedDate dateStyle="medium" value={article.createdAt} />
              }
            />
          </div>
        </div>
      </div>
    </Link>
  );
}
