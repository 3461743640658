import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { VerifyEmailSignupPageQuery as VerifyEmailSignupPageQueryType } from "./__generated__/VerifyEmailSignupPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { VerifyEmailSignupPageQuery as VerifyEmailSignupPageQueryType } from "./__generated__/VerifyEmailSignupPageQuery.graphql";

export const VerifyEmailSignupPageQuery = graphql`
  query VerifyEmailSignupPageQuery($id: ID!) {
    node(id: $id) {
      id
      ... on EmailSignupRequest {
        emailCanResendAt
      }
    }
  }
`;

export const verifyEmailSignupPageQueryLoader = (environment: Environment) => {
  return ({ params: { id } }: LoaderArgs) => {
    if (!id) {
      throw new Response("No id", { status: 500 });
    }
    return preload<VerifyEmailSignupPageQueryType>(
      environment,
      VerifyEmailSignupPageQuery,
      {
        id,
      },
    );
  };
};

export const useVerifyEmailSignupPageQuery = () =>
  usePreloaded<VerifyEmailSignupPageQueryType>();
