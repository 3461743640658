import { FormattedMessage } from "react-intl";
import NavItem, { type Variant } from "./NavItem";
import { MdOutlineArrowOutward } from "react-icons/md";

interface Props {
  showEvents: boolean;
  vertical?: boolean;
  variant?: Variant;
}

export default function NavItems({
  showEvents,
  vertical = false,
  variant = "primary",
}: Props) {
  return (
    <>
      <NavItem to={`/competitions`} vertical={vertical} variant={variant}>
        <FormattedMessage defaultMessage="Competitions" />
      </NavItem>
      {showEvents && (
        <NavItem to={`/events`} vertical={vertical} variant={variant}>
          <FormattedMessage defaultMessage="Events" />
        </NavItem>
      )}
      <NavItem to={`/discussions`} vertical={vertical} variant={variant}>
        <FormattedMessage defaultMessage="Discussions" />
      </NavItem>
      <NavItem to={`/leaderboard`} vertical={vertical} variant={variant}>
        <FormattedMessage defaultMessage="Leaderboard" />
      </NavItem>
      <NavItem to={`/blog`} vertical={vertical} variant={variant}>
        <FormattedMessage defaultMessage="Blog" />
      </NavItem>
      {import.meta.env.VITE_QUANTUM_JOBS && (
        <NavItem
          to={import.meta.env.VITE_QUANTUM_JOBS}
          target="_blank"
          vertical={vertical}
          variant={variant}
          icon={<MdOutlineArrowOutward />}
        >
          <FormattedMessage defaultMessage="Job Board" />
        </NavItem>
      )}
      {import.meta.env.VITE_Q3AS && (
        <NavItem
          to={import.meta.env.VITE_Q3AS}
          target="_blank"
          variant={variant}
          vertical={vertical}
          icon={<MdOutlineArrowOutward />}
        >
          <FormattedMessage defaultMessage="Q3AS" />
        </NavItem>
      )}
    </>
  );
}
